// @ts-nocheck
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { useUserContext } from "../../../../context/UserContext";
import { useAppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import ScanTable from "../../../../components/scan_table";
import TrackTable from "../../../../components/track_table";
import { getEventCardById, eventsTickets } from "../../../../api/event";
import { getShareLinks, getSaleTrack } from "../../../../api/sharelinktrack";
import { getName } from "country-list";
// import CustomDatePicker from "../../../../components/date_picker";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import styles from "./index.module.css";
const PageSalesReport = () => {
  const { t } = useTranslation();
  const { userInfo } = useUserContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setLoading } = useAppContext();
  const [eventCard, setEventCard] = useState();
  const [period, setPeriod] = useState("All time");
  const [soldTickets, setSoldTickets] = useState([]);
  const [allTickets, setAllTickets] = useState([]);
  const [mediaLinks, setMediaLinks] = useState([]);
  const [trackLinks, setTrackLinks] = useState([]);
  const [trackSale, setTrackSale] = useState([]);
  const tableData = useMemo(() => {
    const data = soldTickets.map((ticket: ITicket) => {
      const soldDate = new Date(ticket.createdAt).toString().substring(0, 21);
      return {
        "Sold Date": soldDate,
        "Item Name": eventCard?.name,
        Name: ticket.buyer_name || ticket.buyer.name,
        Email: ticket.buyer_email || ticket.buyer.email,
        Phone: ticket.buyer_phone || ticket.buyer.phone,
        Price:
          (ticket.price * (100 - ticket.discount)) / 100 +
          `${
            eventCard?.currency === "USD"
              ? " $"
              : eventCard?.currency === "TRY"
              ? " ₺"
              : eventCard?.currency === "EUR"
              ? " €"
              : eventCard?.currency === "CLP"
              ? " $(CLP)"
              : eventCard?.currency === "DKK"
              ? " kr."
              : eventCard?.currency === "SEK"
              ? " kr"
              : eventCard?.currency === "MXN"
              ? " $"
              : " ₹"
          }`,
        Tax:
          (!eventCard?.tax_include
            ? (((ticket.price * (100 - ticket.discount)) / 100) *
                eventCard?.tax) /
              100
            : 0) +
          `${
            eventCard?.currency === "USD"
              ? " $"
              : eventCard?.currency === "TRY"
              ? " ₺"
              : eventCard?.currency === "EUR"
              ? " €"
              : eventCard?.currency === "CLP"
              ? " $(CLP)"
              : eventCard?.currency === "DKK"
              ? " kr."
              : eventCard?.currency === "SEK"
              ? " kr"
              : eventCard?.currency === "MXN"
              ? " $"
              : " ₹"
          }`,
        Discount: ticket.discount + "%",
        Count: ticket.count,
        "Payment Method":
          ticket.pay_order_id.search("0x") !== -1
            ? "USDT payment"
            : ticket.pay_order_id,
      };
    });
    return data;
  }, [soldTickets, eventCard]);
  const columnDefs = useMemo<ColDef[]>(
    () => [
      { field: "Sold Date", width: 210 },
      { field: "Item Name", width: 350 },
      { field: "Name", width: 150 },
      { field: "Email", width: 220 },
      { field: "Phone", width: 180 },
      { field: "Price", width: 130 },
      { field: "Tax", width: 130 },
      { field: "Discount", width: 150 },
      { field: "Count", width: 110 },
      { field: "Payment Method", width: 200 },
    ],
    []
  );
  const [allBtickets, setAllBtickets] = useState([]);
  const [scanTickets, setScanTickets] = useState([]);
  const [showPeriod, setShowPeriod] = useState(false);
  const saleReport = useRef(null);
  const periodButton = useRef<HTMLButtonElement>(null);
  const periodBlock = useRef<HTMLDivElement>(null);
  const locationUsers = useMemo(() => {
    const transformedArray = soldTickets.reduce((acc, curr) => {
      const location = curr.buyer.location || "Unknown";

      if (acc[location]) {
        acc[location].count += 1;
      } else {
        acc[location] = {
          location: getName(location) || "Unknown",
          count: 1,
        };
      }

      return acc;
    }, {});
    const finalArray = Object.values(transformedArray);
    return finalArray;
  }, [soldTickets]);
  const scanNumber = useMemo(() => {
    let $scanNumber = 0;
    for (let index = 0; index < soldTickets.length; index++) {
      const element = soldTickets[index];
      $scanNumber += parseInt(element.scan_number);
    }
    return $scanNumber;
  }, [soldTickets]);
  const genderUsers = useMemo(() => {
    let genders = [0, 0, 0];
    for (let index = 0; index < soldTickets.length; index++) {
      const element = soldTickets[index];
      if (element.buyer.gender === "Male") {
        genders[0] += 1;
      } else if (element.buyer.gender === "Female") {
        genders[1] += 1;
      } else {
        genders[2] += 1;
      }
    }
    return genders;
  }, [soldTickets]);
  const ageUsers = useMemo(() => {
    let users = [0, 0, 0, 0, 0, 0];
    for (let index = 0; index < soldTickets.length; index++) {
      const element = soldTickets[index];
      const age = new Date().getFullYear() - Number(element.buyer.date);
      if (age >= 13 && age <= 19) {
        users[0] += 1;
      } else if (age >= 20 && age <= 29) {
        users[1] += 1;
      } else if (age >= 30 && age <= 39) {
        users[2] += 1;
      } else if (age >= 40 && age <= 49) {
        users[3] += 1;
      } else if (age >= 50 && age <= 59) {
        users[4] += 1;
      } else if (age >= 60 && age <= 69) {
        users[5] += 1;
      }
    }
    return users;
  }, [soldTickets]);
  const salesPrice = useMemo(() => {
    let totalPrice = 0;
    for (let index = 0; index < soldTickets.length; index++) {
      const element = soldTickets[index];
      totalPrice +=
        element.price !== 0
          ? eventCard?.price *
            (1 - element.discount / 100) *
            (1 + eventCard?.tax / 100) *
            parseInt(element.count)
          : 0;
    }
    return totalPrice.toFixed();
  }, [eventCard, soldTickets]);

  const totalNumbers = useMemo(() => {
    let total = 0;
    for (let index = 0; index < soldTickets.length; index++) {
      const element = soldTickets[index];
      total += parseInt(element.count);
    }
    return total;
  }, [soldTickets]);

  const trackData = useMemo(() => {
    const joinedArray = trackLinks.map((item1) => {
      const item2 = trackSale.find((item) => item.trackId === item1.trackId);
      return {
        ...item1,
        ...(item2 || {}), // Spread item2 if it exists, otherwise spread an empty object
      };
    });

    return joinedArray;
  }, [trackLinks, trackSale]);

  const remakeTickets = (tickets) => {
    const originalArray = tickets.sort(
      (a: any, b: any) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    const transformedArray = originalArray.reduce((acc, curr) => {
      const date = new Date(curr.createdAt).toISOString().slice(0, 10); // Get only the date part (year-month-day)

      if (acc[date]) {
        acc[date].count += parseInt(curr.count);
      } else {
        acc[date] = {
          createdAt: date,
          count: parseInt(curr.count),
        };
      }

      return acc;
    }, {});

    const finalArray = Object.values(transformedArray);
    return finalArray;
  };

  const saleSeries = useMemo(() => {
    const tickets = remakeTickets(soldTickets);
    let data = tickets.map((item) => item.count);
    return [
      {
        name: "Sales revenue",
        data,
        markers: {
          show: true,
        },
      },
    ];
  }, [soldTickets]);

  const saleOptions = useMemo(() => {
    const tickets = remakeTickets(soldTickets);
    let data = tickets.map((item) => item.createdAt);
    return {
      chart: {
        type: "area",
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#6A4DFD"],
      stroke: {
        curve: "straight",
        width: 3,
      },
      markers: {
        size: 5,
        fillColors: "#6A4DFD",
        strokeWidth: 0,
        show: true,
      },
      xaxis: {
        categories: data,
        labels: {
          style: {
            colors: "#FFFFFFA8",
          },
        },
        borderColor: "#FFFFFF1A",
        min: 0,
        max: tickets.length + 1,
      },
      yaxis: {
        min: 0,
        axisBorder: {
          color: "#6A4DFD",
        },
        labels: {
          style: {
            colors: "#FFFFFFA8",
          },
          formatter: function(value) {
            if (!Number.isInteger(value)) return "";
            else return value;
          },
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      grid: {
        borderColor: "#FFFFFF1A",
      },
    };
  }, [soldTickets]);

  const system_series = [8, 40, 25, 15, 6, 4, 2];

  const system_options = {
    labels: [
      "Macos",
      "Windows",
      "iOS",
      "Android",
      "Linux",
      "Chrome OS",
      "iPad OS",
    ],
    colors: [
      "#94D13D",
      "#6F58E9",
      "#2D99FE",
      "#2DDAC1",
      "#B3B461",
      "#DC7B5D",
      "#147C8D",
    ],
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
      },
    ],
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        console.log(series, seriesIndex, w.config.labels[seriesIndex]);
        const total = series.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        return `<div class=${styles.tooltip}>
                  <p class=${styles.tooltip__field}>${
          w.config.labels[seriesIndex]
        }</p>
                  <p class=${styles.tooltip__value}>${
          series[seriesIndex]
        } buyers</p>
                  <p class=${styles.tooltip__value}>${(
          (series[seriesIndex] / total) *
          100
        ).toFixed(1)} %</p>
                </div>`;
      },
    },
    stroke: {
      show: false,
    },
    legend: {
      position: "bottom",
      offsetY: 5,
      height: 80,
      fontSize: "11px",
      labels: {
        colors: "#fff",
      },
    },
  };

  const week_series = [18, 18, 8, 9, 24, 16, 17];

  const week_options = {
    labels: [
      "Friday",
      "Saturday",
      "Sunday",
      "Thursday",
      "Wednesday",
      "Tuesday",
      "Monday",
    ],
    colors: [
      "#94D13D",
      "#6F58E9",
      "#2D99FE",
      "#914D24",
      "#1C501B",
      "#B36811",
      "#F0E56C",
    ],
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
      },
    ],
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        console.log(series, seriesIndex, w.config.labels[seriesIndex]);
        const total = series.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        return `<div class=${styles.tooltip}>
                  <p class=${styles.tooltip__field}>${
          w.config.labels[seriesIndex]
        }</p>
                  <p class=${styles.tooltip__value}>${
          series[seriesIndex]
        } buyers</p>
                  <p class=${styles.tooltip__value}>${(
          (series[seriesIndex] / total) *
          100
        ).toFixed(1)} %</p>
                </div>`;
      },
    },
    stroke: {
      show: false,
    },
    legend: {
      position: "bottom",
      offsetY: 5,
      height: 80,
      fontSize: "11px",
      labels: {
        colors: "#fff",
      },
    },
  };

  const age_options = {
    labels: ["13-19", "20-29", "30-39", "40-49", "50-59", "60-69"],
    colors: ["#94D13D", "#6F58E9", "#2D99FE", "#2DDAC1", "#E27DED", "#155DEB"],
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
      },
    ],
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        console.log(series, seriesIndex, w.config.labels[seriesIndex]);
        const total = series.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        return `<div class=${styles.tooltip}>
                  <p class=${styles.tooltip__field}>${
          w.config.labels[seriesIndex]
        }</p>
                  <p class=${styles.tooltip__value}>${
          series[seriesIndex]
        } buyers</p>
                  <p class=${styles.tooltip__value}>${(
          (series[seriesIndex] / total) *
          100
        ).toFixed(1)} %</p>
                </div>`;
      },
    },
    stroke: {
      show: false,
    },
    legend: {
      position: "bottom",
      offsetY: 5,
      height: 80,
      fontSize: "11px",
      labels: {
        colors: "#fff",
      },
    },
  };

  const gender_options = {
    labels: ["Male", "Female", "Unknown"],
    colors: ["#94D13D", "#6F58E9", "#2D99FE"],
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
      },
    ],
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        console.log(series, seriesIndex, w.config.labels[seriesIndex]);
        const total = series.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        return `<div class=${styles.tooltip}>
                  <p class=${styles.tooltip__field}>${
          w.config.labels[seriesIndex]
        }</p>
                  <p class=${styles.tooltip__value}>${
          series[seriesIndex]
        } buyers</p>
                  <p class=${styles.tooltip__value}>${(
          (series[seriesIndex] / total) *
          100
        ).toFixed(1)} %</p>
                </div>`;
      },
    },
    stroke: {
      show: false,
    },
    legend: {
      position: "bottom",
      offsetY: 5,
      height: 80,
      fontSize: "11px",
      labels: {
        colors: "#fff",
      },
    },
  };

  const sortScanTickets = (scanTickets) => {
    const data = scanTickets.sort(
      (item1: ITicket, item2: ITicket) =>
        new Date(item2.createdAt).getTime() -
        new Date(item1.createdAt).getTime()
    );
    setScanTickets(sortData(data));
  };

  const sortSoldTickets = (data) => {
    setSoldTickets(sortData(data));
  };

  const sortData = (data) => {
    const currentDate = new Date();
    if (period === "All time") {
      return data;
    } else if (period === "Today") {
      const today = new Date().toISOString().slice(0, 10);

      const filteredData = data.filter(
        (item) => item.createdAt.slice(0, 10) === today
      );
      return filteredData;
    } else if (period === "This week") {
      const dayOfWeek = currentDate.getDay();
      const startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - dayOfWeek);
      const endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + (6 - dayOfWeek));
      const filteredData = data.filter((item) => {
        const createdAtDate = new Date(item.createdAt);
        return createdAtDate >= startDate && createdAtDate <= endDate;
      });
      return filteredData;
    } else if (period === "Last week") {
      const lastWeekStartDate = new Date(currentDate);
      lastWeekStartDate.setDate(
        currentDate.getDate() - currentDate.getDay() - 6
      );
      const lastWeekEndDate = new Date(currentDate);
      lastWeekEndDate.setDate(currentDate.getDate() - currentDate.getDay() - 1);
      const filteredData = data.filter((item) => {
        const createdAtDate = new Date(item.createdAt);
        return (
          createdAtDate >= lastWeekStartDate && createdAtDate <= lastWeekEndDate
        );
      });
      return filteredData;
    } else if (period === "This month") {
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      const currentMonthStartDate = new Date(currentYear, currentMonth, 1);

      const nextMonthStartDate = new Date(currentYear, currentMonth + 1, 1);
      const currentMonthEndDate = new Date(nextMonthStartDate - 1);

      const filteredData = data.filter((item) => {
        const createdAtDate = new Date(item.createdAt);
        return (
          createdAtDate >= currentMonthStartDate &&
          createdAtDate <= currentMonthEndDate
        );
      });
      return filteredData;
    } else if (period === "Last month") {
      const lastMonthStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );

      const lastMonthEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      );

      const filteredData = data.filter((item) => {
        const createdAtDate = new Date(item.createdAt);
        return (
          createdAtDate >= lastMonthStartDate &&
          createdAtDate <= lastMonthEndDate
        );
      });
      return filteredData;
    } else if (period === "Last 3 months") {
      const lastThreeMonthsStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 2,
        1
      );

      const lastMonthEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      );

      const filteredData = data.filter((item) => {
        const createdAtDate = new Date(item.createdAt);
        return (
          createdAtDate >= lastThreeMonthsStartDate &&
          createdAtDate <= lastMonthEndDate
        );
      });
      return filteredData;
    } else {
      return data;
    }
  };

  const handleOutsideClick = (event: any) => {
    if (
      periodButton.current &&
      !periodButton.current.contains(event.target) &&
      periodBlock.current &&
      !periodBlock.current.contains(event.target)
    ) {
      setShowPeriod(false);
    }
  };

  const saleExport = () => {
    saleReport.current.api.exportDataAsCsv({
      fileName: `salesReport(${eventCard?.name}).csv`,
    });
  };

  const sortMediaLinks = (data) => {
    const groupedByMedia = data.reduce((acc, current) => {
      // Check if the media group already exists
      if (!acc[current.media]) {
        acc[current.media] = []; // Create a new array for this media
      }
      // Push the current item into the appropriate media group
      acc[current.media].push(current);
      return acc;
    }, {});

    // Convert the grouped object to an array if needed
    const resultArray = Object.entries(
      groupedByMedia
    ).map(([media, items]) => ({ media, items }));

    setMediaLinks(resultArray);
  };

  const sortLinksbyTrack = (data) => {
    const groupedByTrack = data.reduce((acc, current) => {
      // Check if the media group already exists
      if (!acc[current.trackId]) {
        acc[current.trackId] = []; // Create a new array for this media
      }
      // Push the current item into the appropriate media group
      acc[current.trackId]++;
      return acc;
    }, {});

    // Convert the grouped object to an array if needed
    const resultArray = Object.entries(
      groupedByTrack
    ).map(([trackId, views]) => ({ trackId, views }));

    setTrackLinks(resultArray);
  };

  const sortSaleTrack = (data) => {
    const groupedByTrackId = data.reduce((acc, current) => {
      const trackId = current.trackId;

      // Initialize if not exists
      if (!acc[trackId]) {
        acc[trackId] = {
          trackId: trackId,
          amount: 0,
          price: 0,
        };
      }

      // Add amount and price
      acc[trackId].amount += parseInt(current.amount);
      acc[trackId].price += parseFloat(current.price.replace("$", ""));

      return acc;
    }, {});

    // Convert the grouped object to an array
    const resultArray = Object.values(groupedByTrackId).map((item) => ({
      ...item,
      price: `${item.price.toFixed(2)}$`, // Format price back to string with $ sign
    }));

    setTrackSale(resultArray);
  };

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.user.user_type === "ADMIN" ||
        (userInfo.user.user_type === "BUSINESS" &&
          userInfo.user.business_allow))
    ) {
      setLoading(true);
      getEventCardById(id)
        .then((res) => {
          setEventCard(res.eventcard);
        })
        .catch((error) => {
          console.log(error);
        });
      getShareLinks({ id }).then((res) => {
        sortMediaLinks(res);
        sortLinksbyTrack(res);
      });
      getSaleTrack({ id }).then((res) => {
        sortSaleTrack(res);
      });
      eventsTickets({ eventCardId: id })
        .then((res) => {
          setAllTickets(res.ticket);
          setAllBtickets(res.bticket);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Attach the global click event listener
    document.addEventListener("click", handleOutsideClick);

    // Return a cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    sortSoldTickets(allTickets);
    sortScanTickets(allBtickets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTickets, period, allBtickets]);

  return (
    <div className={styles.report__container}>
      <Link to="/sales/overview">
        <div className={styles.back__container}>
          <img src="/img/icons/arrow-left.svg" alt="back" />
          <p className={styles.back__text}>{t("back")}</p>
        </div>
      </Link>

      <div className={styles.title__container}>
        <img src="/img/icons/sales.svg" alt="sales" />
        <p className={styles.title}>
          Sales Reports <span className={styles.divide__line}>/</span>{" "}
          {eventCard?.name}
        </p>
      </div>

      <div className={styles.btn__group}>
        <button
          className={styles.calendar__btn}
          onClick={() => setShowPeriod(!showPeriod)}
          ref={periodButton}
        >
          <img src="/img/icons/calendar.svg" alt="calendar" />
          <p className={styles.btn__text}>{period}</p>
          <img src="/img/icons/chevron-right2.svg" alt="down" />
        </button>
        <button className={styles.export__btn} onClick={saleExport}>
          <img src="/img/icons/export.svg" alt="export" />
          <p className={styles.btn__text} style={{ marginRight: 0 }}>
            Export Data
          </p>
        </button>
        <div
          ref={periodBlock}
          className={styles.date__picker}
          style={{ display: showPeriod ? "block" : "none" }}
        >
          <p className={styles.picker__title}>Filter by period</p>
          <div>
            <label className={styles.radio__container}>
              All time
              <input
                type="radio"
                checked={period === "All time"}
                name="radio"
                onChange={(e) => setPeriod(e.target.value)}
                value="All time"
              />
              <span className={styles.radio__checkmark}></span>
            </label>
            <label className={styles.radio__container}>
              Today
              <input
                type="radio"
                checked={period === "Today"}
                name="radio"
                onChange={(e) => setPeriod(e.target.value)}
                value="Today"
              />
              <span className={styles.radio__checkmark}></span>
            </label>
            <label className={styles.radio__container}>
              This week
              <input
                type="radio"
                checked={period === "This week"}
                name="radio"
                onChange={(e) => setPeriod(e.target.value)}
                value="This week"
              />
              <span className={styles.radio__checkmark}></span>
            </label>
            <label className={styles.radio__container}>
              Last week
              <input
                type="radio"
                checked={period === "Last week"}
                name="radio"
                onChange={(e) => setPeriod(e.target.value)}
                value="Last week"
              />
              <span className={styles.radio__checkmark}></span>
            </label>
            <label className={styles.radio__container}>
              This month
              <input
                type="radio"
                checked={period === "This month"}
                name="radio"
                onChange={(e) => setPeriod(e.target.value)}
                value="This month"
              />
              <span className={styles.radio__checkmark}></span>
            </label>
            <label className={styles.radio__container}>
              Last month
              <input
                type="radio"
                checked={period === "Last month"}
                name="radio"
                onChange={(e) => setPeriod(e.target.value)}
                value="Last month"
              />
              <span className={styles.radio__checkmark}></span>
            </label>
            <label className={styles.radio__container}>
              Last 3 months
              <input
                type="radio"
                checked={period === "Last 3 months"}
                name="radio"
                onChange={(e) => setPeriod(e.target.value)}
                value="Last 3 months"
              />
              <span className={styles.radio__checkmark}></span>
            </label>
            {/* <label className={styles.radio__container}>
              Custom period
              <input
                type="radio"
                checked={period === "Custom period"}
                name="radio"
                onChange={(e) => setPeriod(e.target.value)}
                value="Custom period"
              />
              <span className={styles.radio__checkmark}></span>
            </label>
            <div className={styles.custom__container}>
              <CustomDatePicker title="From" />
              <CustomDatePicker title="To" />
            </div> */}
          </div>
        </div>
      </div>

      <div className={styles.top__block}>
        <div className={styles.block__item}>
          <p className={styles.item__title}>Total Sales</p>
          <p className={styles.item__content}>
            {salesPrice} {eventCard?.currency}{" "}
          </p>
        </div>
        <div className={styles.block__item}>
          <p className={styles.item__title}>Total Number of Products Sold</p>
          <p className={styles.item__content}>{totalNumbers}</p>
        </div>
        <div className={styles.block__item}>
          <p className={styles.item__title}>Scan Tickets</p>
          <p className={styles.item__content}>{scanNumber}</p>
        </div>
        <div className={styles.block__item}>
          <p className={styles.item__title}>Total Product Page Views</p>
          <p className={styles.item__content}>{eventCard?.views}</p>
        </div>
      </div>

      <div className={styles.chart__container}>
        <div className={styles.chart__block}>
          <p className={styles.chart__title}>Sales revenue overview</p>
          <Chart options={saleOptions} series={saleSeries} type="area" />
        </div>
        <div className={styles.media__block}>
          <p className={styles.sold__title}>Page views by social media</p>
          {mediaLinks.map((item, index) => (
            <div className={styles.item__block} key={index}>
              <div className={styles.media__item}>
                {item.media.toLowerCase() === "instagram" ||
                item.media.toLowerCase() === "facebook" ||
                item.media.toLowerCase() === "x" ||
                item.media.toLowerCase() === "tiktok" ||
                item.media.toLowerCase() === "youtube" ||
                item.media.toLowerCase() === "telegram" ? (
                  <img
                    src={`/img/media/${item.media.toLowerCase()}.png`}
                    width={32}
                    alt="media"
                  />
                ) : (
                  <img
                    src={`/img/media/backstage.png`}
                    width={32}
                    alt="media"
                  />
                )}
                <div>
                  <p className={styles.ticket__name}>{item.media}</p>
                  <p className={styles.sold__number}>{item.items.length}</p>
                </div>
              </div>
              <p className={styles.percent__text}>
                {eventCard?.views !== 0
                  ? ((item.items.length / eventCard?.views) * 100).toFixed()
                  : 0}
                %
              </p>
            </div>
          ))}
        </div>
      </div>

      <ScanTable tickets={scanTickets} />
      <TrackTable data={trackData} />

      <div className="ag-theme-alpine table-wrapper mb-5 mt-4 report__table">
        <AgGridReact
          className="w-full h-full ag-grid-table"
          rowClass={["custom-row"]}
          rowData={tableData}
          headerHeight={60}
          rowHeight={60}
          debounceVerticalScrollbar
          columnDefs={columnDefs}
          suppressRowHoverHighlight={false}
          ref={saleReport}
        ></AgGridReact>
      </div>

      <div className={styles.map__container}>
        <p className={styles.map__title}>Customers by country</p>
        <div className={styles.map__block}>
          <img
            src="/img/world_map.png"
            className={styles.world__map}
            alt="world"
          />
          <div>
            {locationUsers.map(
              (item, index) =>
                item.location !== "Unknown" &&
                index < 5 && (
                  <div className={styles.map__item} key={index}>
                    <div className={styles.flag__container}>
                      <img
                        className={styles.flag}
                        src={`/img/flag/${item.location.toLowerCase()}.png`}
                        alt="flag"
                      />
                      <p className={styles.country__name}>{item.location}</p>
                    </div>
                    <p className={styles.map__percent}>{item.count}</p>
                  </div>
                )
            )}
          </div>
        </div>
      </div>

      <div className={styles.pie__container}>
        <div className={styles.system__container}>
          <p className={styles.pie__title}>Customers by operating system</p>
          <Chart
            options={system_options}
            series={system_series}
            type="donut"
            height={300}
          />
        </div>
        <div className={styles.system__container}>
          <p className={styles.pie__title}>Customers by days in the week</p>
          <Chart
            options={week_options}
            series={week_series}
            type="donut"
            height={300}
          />
        </div>
        <div className={styles.system__container}>
          <p className={styles.pie__title}>Customers by age group</p>
          <Chart
            options={age_options}
            series={ageUsers}
            type="donut"
            height={300}
          />
        </div>
        <div className={styles.system__container}>
          <p className={styles.pie__title}>Customers by gender</p>
          <Chart
            options={gender_options}
            series={genderUsers}
            type="donut"
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default PageSalesReport;
