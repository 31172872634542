/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout_form";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";

import useConvertedFiat from "../../../hooks/useConvertedFiat";
import useFiatSymbol from "../../../hooks/useFiatSymbol";
import { createPaymentIntent } from "../../../api/credit";

import { getEventPrice } from "../../../utils";
import { convertHtmlToString } from "../../../utils/convertHtmlToString";
import { CURRENCY } from "../../../utils/currency";

import styles from "./index.module.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeElementModal = ({ eventCard, amount }) => {
  console.log("stripe element");
  const {
    setLoading,
    country,
    discount,
    selectedSeats,
    media,
    trackId,
  } = useAppContext();

  const isMobile = useMediaQuery("(max-width: 576px)");
  const { userInfo } = useUserContext();
  const [wallet] = useState(userInfo.user.wallet_address);
  const [clientSecret, setClientSecret] = useState("");
  const symbol = useFiatSymbol(country);
  const convertedPrice = useConvertedFiat(
    getEventPrice(eventCard),
    eventCard.currency,
    country
  );

  const totalCurrencyPrice =
    (
      convertedPrice *
      (1 + (eventCard.tax_include ? 0 : eventCard.tax / 100)) *
      amount
    ).toFixed(2) + symbol;

  const ticketCurrencyPrice =
    (
      convertedPrice *
      (1 + (eventCard.tax_include ? 0 : eventCard.tax / 100))
    ).toFixed(2) + symbol;
  const emailData = {
    mobile: isMobile,
    email: userInfo.user.email,
    ticket_number: Number(amount),
    user_name: userInfo.user.name,
    totalPrice: totalCurrencyPrice,
    ticketPrice: ticketCurrencyPrice,
    collection_name: eventCard.collection.name,
    scan: eventCard.scan,
    ticket_type: eventCard.collection.category,
    item: eventCard,
    addons: JSON.parse(eventCard.addons),
    start_now:
      eventCard.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
    date: new Date(localStorage.getItem("service_date") || eventCard.date)
      .toString()
      .substring(0, 21),
    end_date: new Date(
      localStorage.getItem("service_date") || eventCard.end_date
    )
      .toString()
      .substring(0, 21),
    country,
  };
  const ticketData = {
    wallet_address: wallet,
    blockchain: "Binance Smart Chain",
    buyer: userInfo.user.id,
    eventcard: eventCard.id,
    collection: eventCard.collection.id,
    price: getEventPrice(eventCard) * amount,
    pay_order_id: "stripe payment",
    count: amount,
    service_date: localStorage.getItem("service_date")
      ? new Date(localStorage.getItem("service_date"))
          .toString()
          .substring(0, 16)
      : null,
    seats: JSON.stringify(selectedSeats),
    discount: discount * 100,
    media,
  };

  const tokenObject = {
    name: eventCard.name,
    description:
      eventCard.category !== "Category2"
        ? convertHtmlToString(eventCard.venue_description)
        : convertHtmlToString(eventCard.description),
    image: eventCard.picture_ipfs,
    attributes: [
      {
        trait_type: "Price",
        value: getEventPrice(eventCard).toFixed(2),
      },
      { trait_type: "Currency", value: symbol },
      { trait_type: "Location", value: eventCard.location },
      { trait_type: "Date", value: eventCard.date },
      { trait_type: "Collection", value: eventCard.collection.name },
      {
        trait_type: "Addons",
        value: JSON.parse(eventCard.addons),
      },
    ],
  };

  const dataObject = {
    event: {
      id: eventCard.id,
      name: eventCard.name,
      price: parseInt(convertedPrice * 100) * amount,
    },
    currency: CURRENCY[country],
    emailData,
    ticketData,
    tokenObject,
    userWallet: userInfo.user.wallet_address,
    contractAddress: eventCard.NFT_address,
    picture_ipfs: eventCard.picture_ipfs,
    trackId,
  };

  useEffect(() => {
    localStorage.setItem("ccObject", JSON.stringify(dataObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    createPaymentIntent({
      amount: eventCard.tax_include
        ? parseInt(convertedPrice * 100 * amount)
        : parseInt(
            (convertedPrice * 100 + convertedPrice * eventCard.tax) * amount
          ),
      currency: CURRENCY[country],
      description:
        eventCard.name +
        ", ticket price: " +
        (convertedPrice * amount).toFixed(2) +
        CURRENCY[country] +
        ", VAT: " +
        ((convertedPrice * amount * eventCard.tax) / 100).toFixed(2) +
        CURRENCY[country],
      metadata: {
        base_price: (convertedPrice * amount).toFixed(2) + CURRENCY[country],
        vat_amount:
          ((convertedPrice * amount * eventCard.tax) / 100).toFixed(2) +
          CURRENCY[country],
      },
      collectionId: eventCard.collection.id,
      email: userInfo.user.email,
    }).then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
      setClientSecret(res.clientSecret);
      localStorage.setItem("stripe_token", res.token);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return clientSecret ? (
    <div className={styles.container}>
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  ) : (
    <></>
  );
};

export default StripeElementModal;
