import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";

import { buyTicket } from "../../../api/event";
import { verifyPromoCode } from "../../../api/promo";
import { getTicketById } from "../../../api/event";
import { sendMailTicket } from "../../../api/message";
// import useAnalyticsEventTracker from "../../../utils/ga";
import {
  USDTMainAddress,
  USDTPayment_ABI,
  USDT_MAIN_ABI,
  paymentAddress_mainnet,
} from "../../../utils/payment_contract";
// import ParamModal from "../param_modal/index.js";
// import StripeModal from "../stripe_modal";
import OKXModal from "../okx_modal";
import StripeElementModal from "../stripe_element_modal";
import StripeTestElementModal from "../test_stripe_element_modal";
import ZamnaStripeElementModal from "../stripe_element_modal/zamna";
import MercadoModal from "../mercado_modal";
import SwishElementModal from "../swish_element_modal";
import MobilePayModal from "../mobile_pay_modal";
import PaymentSuccessModal from "../payment_success_modal";
import PaymentFailedModal from "../payment_failed_modal";
import useConvertedFiat from "../../../hooks/useConvertedFiat";
import useNoDiscountFiat from "../../../hooks/useNoDiscountFiat";
import useFiatSymbol from "../../../hooks/useFiatSymbol";
import FiatSymbol from "../../FiatSymbol";
import TermsConditionModal from "../terms_conditions_modal";
import { getEventPrice } from "../../../utils";
import PasswordModal from "../password_modal";
import client from "../../../utils/ipfs_client";
import { convertHtmlToString } from "../../../utils/convertHtmlToString";
// import { swishPayment } from "../../../api/credit";
import styles from "./index.module.css";

const TicketBuyModal = ({ eventCard }) => {
  const {
    setLoading,
    setModal,
    country,
    addToast,
    discount,
    setDiscount,
    selectedSeats,
    rateEURvsUSD,
    rateTRYvsUSD,
    rateINRvsUSD,
    rateCLPvsUSD,
    rateGBPvsUSD,
    rateDKKvsUSD,
    rateSEKvsUSD,
    rateMXNvsUSD,
    setTicketAmount,
    ticketAmount,
    media,
    trackId,
  } = useAppContext();
  const { userInfo } = useUserContext();
  const [terms, setTerms] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoSuccess, setPromoSuccess] = useState(0);
  const symbol = useFiatSymbol(country);
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 576px)");
  // const gaEventTracker = useAnalyticsEventTracker("Buy Ticket");
  const locale = navigator.language.split("-")[0];
  // const usdPrice = useConvertedFiat(getEventPrice(eventCard), eventCard.currency, "US");
  // const convertedPrice = useConvertedFiat(
  //   getEventPrice(eventCard),
  //   eventCard.currency,
  //   country
  // );

  const noDiscountPrice = useNoDiscountFiat(
    getEventPrice(eventCard),
    eventCard.currency,
    country
  );

  useEffect(() => {
    if (getEventPrice(eventCard) === 0) {
      handleBuyTicket(
        "free ticket",
        userInfo.user.wallet_address,
        "Binance Smart Chain"
      );
      setModal({ open: false });
    }
    if (selectedSeats.length > 0) {
      setTicketAmount(selectedSeats.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buyInUSDT = async (provide) => {
    const provider = new ethers.providers.Web3Provider(provide);
    const chainId = Number(provider.provider.chainId);
    console.log(chainId);
    if (chainId !== 56) {
      addToast("Please change the network BNB chain", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      addToast("Please wait ... It might takes some time", {
        appearance: "warning",
        autoDismiss: true,
      });
      setLoading(true);
      try {
        const account = await provider.getSigner().getAddress();
        const USDT = new ethers.Contract(
          USDTMainAddress,
          USDT_MAIN_ABI,
          provider.getSigner()
        );
        const contract = new ethers.Contract(
          paymentAddress_mainnet,
          USDTPayment_ABI,
          provider.getSigner()
        );
        const price = calcPrice(eventCard.currency, getEventPrice(eventCard));
        const totalUSDT = await USDT.balanceOf(account);
        if (ethers.BigNumber.from(totalUSDT).lt(price)) {
          addToast("You don't have enough USDT in your wallet", {
            appearance: "error",
            autoDismiss: true,
          });
          setLoading(false);
        } else {
          try {
            let txn = await USDT.approve(paymentAddress_mainnet, price);
            await txn.wait();
            const _payees = JSON.parse(eventCard.payees).map(
              (payee) => payee.wallet
            );
            const _fees = JSON.parse(eventCard.payees).map((payee) =>
              Number(Number(payee.fee).toFixed())
            );
            txn = await contract.payWithBUSD(
              account,
              eventCard.owner_wallet,
              _payees,
              _fees,
              price
            );
            await txn.wait();
            handleBuyTicket(txn.hash, account, "Binance Smart Chain");
          } catch (error) {
            console.log(error);
            setLoading(false);
            setModal({
              open: true,
              children: <PaymentFailedModal />,
            });
          }
        }
      } catch (err) {
        setLoading(false);
        addToast(err.message, { appearance: "warning", autoDismiss: true });
      }
    }
  };

  const buyWithUSDT = async (_provide) => {
    let provide = null;
    if (isMobile) {
      if (_provide === "Metamask") {
        if (!window.ethereum) {
          window.location.href = `https://metamask.app.link/dapp/backstage.global/event/eventcard/${eventCard.id}`;
        } else {
          provide = window.ethereum;
          const accounts = await provide.request({ method: "eth_accounts" });
          if (accounts.length === 0)
            await provide.request({ method: "eth_requestAccounts" });
          buyInUSDT(provide);
        }
      } else {
        if (!window.ethereum) {
          window.location.href = `https://bkcode.vip?action=dapp&url=https://backstage.global/event/eventcard/${eventCard.id}`;
        } else {
          provide = window.bitkeep.ethereum;
          const accounts = await provide.request({ method: "eth_accounts" });
          if (accounts.length === 0)
            await provide.request({ method: "eth_requestAccounts" });
          buyInUSDT(provide);
        }
      }
    } else {
      if (_provide === "Bitkeep" && window.isBitKeep) {
        provide = window.bitkeep.ethereum;
      } else if (_provide === "Metamask" && window.ethereum) {
        provide = window.ethereum;
      }

      if (provide === null) {
        addToast("You need to install " + _provide, {
          appearance: "warning",
          autoDismiss: true,
        });
        return;
      }

      const accounts = await provide.request({ method: "eth_accounts" });
      if (accounts.length === 0)
        await provide.request({ method: "eth_requestAccounts" });
      buyInUSDT(provide);
    }
  };

  const buyWithBKSWallet = async () => {
    const price = calcPrice(eventCard.currency, getEventPrice(eventCard));
    const data = {
      price,
      payees: eventCard.payees,
      owner_wallet: eventCard.owner_wallet,
    };
    setModal({
      open: true,
      children: <PasswordModal data={data} handleBuyTicket={handleBuyTicket} />,
    });
  };

  const calcPrice = (currency, eventPrice) => {
    const _EURprice = ethers.BigNumber.from(
      Math.floor(rateEURvsUSD * 100000000)
    );
    const _INRprice = ethers.BigNumber.from(
      Math.floor(rateINRvsUSD * 100000000)
    );
    const _CLPprice = ethers.BigNumber.from(
      Math.floor(rateCLPvsUSD * 100000000)
    );
    const _DKKprice = ethers.BigNumber.from(
      Math.floor(rateDKKvsUSD * 100000000)
    );
    const _SEKprice = ethers.BigNumber.from(
      Math.floor(rateSEKvsUSD * 100000000)
    );
    const _TRYprice = ethers.BigNumber.from(
      Math.floor(rateTRYvsUSD * 100000000)
    );
    const _GBPprice = ethers.BigNumber.from(
      Math.floor(rateGBPvsUSD * 100000000)
    );
    const _MXNprice = ethers.BigNumber.from(
      Math.floor(rateMXNvsUSD * 100000000)
    );
    const _USDprice = ethers.BigNumber.from(100000000);
    const price = (currency === "USD"
      ? _USDprice
      : currency === "INR"
      ? _INRprice
      : currency === "CLP"
      ? _CLPprice
      : currency === "DKK"
      ? _DKKprice
      : currency === "SEK"
      ? _SEKprice
      : currency === "EUR"
      ? _EURprice
      : currency === "TRY"
      ? _TRYprice
      : currency === "MXN"
      ? _MXNprice
      : _GBPprice
    )
      .mul(ethers.BigNumber.from(ticketAmount))
      .mul(
        ethers.BigNumber.from(
          Math.floor(
            eventPrice * (1 - discount) * (1 + eventCard.tax / 100) * 1000
          )
        )
      )
      .mul(ethers.BigNumber.from(10000000));
    return price;
  };

  const handleBuyTicket = async (orderid, _wallet, _chain) => {
    setLoading(true);
    const tokenObject = {
      name: eventCard?.name,
      description:
        eventCard.category !== "Category2"
          ? convertHtmlToString(eventCard.venue_description)
          : convertHtmlToString(eventCard.description),
      image: eventCard.picture_ipfs,
      attributes: [
        {
          trait_type: "Price",
          value:
            getEventPrice(eventCard) *
            (1 - discount) *
            (1 + eventCard.tax / 100).toFixed(2),
        },
        { trait_type: "Currency", value: symbol },
        { trait_type: "Location", value: eventCard.location },
        { trait_type: "Date", value: eventCard.date },
        { trait_type: "Collection", value: eventCard.collection.name },
        {
          trait_type: "Addons",
          value: JSON.parse(eventCard.addons),
        },
      ],
    };
    const added = await client.add(JSON.stringify(tokenObject));
    const ipfs_url = `https://bkstage.infura-ipfs.io/ipfs/${added.path}`;
    const nftData = {
      contract: eventCard.NFT_address,
      IPFS_URL: ipfs_url,
      account: userInfo.user.wallet_address,
      picture_ipfs: eventCard.picture_ipfs,
    };
    const ticketData = {
      wallet_address: _wallet,
      blockchain: _chain,
      eventcard: eventCard.id,
      collection: eventCard.collection.id,
      price:
        getEventPrice(eventCard) *
        (1 - discount) *
        (1 + eventCard.tax / 100) *
        ticketAmount,
      pay_order_id: orderid,
      count: ticketAmount,
      buyer: userInfo.user.id,
      service_date: localStorage.getItem("service_date")
        ? new Date(localStorage.getItem("service_date"))
            .toString()
            .substring(0, 16)
        : null,
      seats: JSON.stringify(selectedSeats),
      discount: discount * 100,
      media,
    };
    const allData = {
      ticketData,
      nftData,
      trackId,
    };
    buyTicket(allData)
      .then((res) => {
        if (res.success) {
          // gaEventTracker(
          //   "purchased",
          //   "Purchased " + ticketAmount + " of ticket: " + eventCard.name
          // );
          handleBought(res.ticket.tokenURL, res.ticket.id);
        } else {
          setDiscount(0);
          setLoading(false);
          setModal({
            open: true,
            children: <PaymentFailedModal />,
          });
        }
      })
      .catch((error) => {
        setDiscount(0);
        setLoading(false);
        addToast("failed", { appearance: "error", autoDismiss: true });
        setModal({ open: false });
      });
  };

  const totalCurrencyPrice =
    useConvertedFiat(eventCard?.price, eventCard?.currency, country) *
      (selectedSeats.length === 0
        ? Number(ticketAmount)
        : selectedSeats.length) +
    " " +
    useFiatSymbol(country);

  const ticketCurrencyPrice =
    useConvertedFiat(eventCard?.price, eventCard?.currency, country) +
    " " +
    useFiatSymbol(country);

  const handleBought = async (tokenURL, ticketId) => {
    setLoading(true);
    const res = await getTicketById({ id: ticketId });
    let url = new URL(res.ticket.tokenURL);
    let params = new URLSearchParams(url.search);
    let token_id = params.get("a");
    const emailData = {
      mobile: isMobile,
      email: userInfo.user.email,
      ticket_number:
        selectedSeats.length === 0
          ? Number(ticketAmount)
          : selectedSeats.length,
      user_name: userInfo.user.name,
      ticketId,
      totalPrice: totalCurrencyPrice,
      ticketPrice: ticketCurrencyPrice,
      collection_name: eventCard.collection.name,
      scan: eventCard.scan,
      ticket_type: eventCard.collection.category,
      item: eventCard,
      seats: selectedSeats,
      addons: JSON.parse(eventCard.addons),
      start_now:
        eventCard.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
      date: new Date(localStorage.getItem("service_date") || eventCard.date)
        .toString()
        .substring(0, 21),
      end_date: new Date(
        localStorage.getItem("service_date") || eventCard.end_date
      )
        .toString()
        .substring(0, 21),
      wallet: userInfo?.user?.wallet_address,
      nft_address: res.ticket.nft_address,
      token_id,
      country,
      tokenURL,
    };
    localStorage.removeItem("service_date");

    sendMailTicket(emailData)
      .then((res) => {
        setLoading(false);
        setDiscount(0);
        setModal({
          open: true,
          children: <PaymentSuccessModal />,
        });
      })
      .catch((err) => {
        setLoading(false);
        setDiscount(0);
        setModal({
          open: true,
          children: <PaymentFailedModal />,
        });
      });
  };

  const buyWithCC = () => {
    if (eventCard.collection.id === process.env.REACT_APP_MERCADO_COLLECTION) {
      setModal({
        open: true,
        children: <MercadoModal eventCard={eventCard} amount={ticketAmount} />,
      });
    } else {
      if (eventCard.collection.id === process.env.REACT_APP_ZAMNA_COLLECTION) {
        setModal({
          open: true,
          children: (
            <ZamnaStripeElementModal
              eventCard={eventCard}
              amount={ticketAmount}
            />
          ),
        });
      } else {
        if (
          eventCard.collection.id === "39614331-fb35-4983-99a9-85cb92d416cf"
        ) {
          setModal({
            open: true,
            children: (
              <StripeTestElementModal
                eventCard={eventCard}
                amount={ticketAmount}
              />
            ),
          });
        } else {
          setModal({
            open: true,
            children: (
              <StripeElementModal eventCard={eventCard} amount={ticketAmount} />
            ),
          });
        }
      }
    }
  };

  const buyWithSwish = async () => {
    setModal({
      open: true,
      children: (
        <SwishElementModal eventCard={eventCard} amount={ticketAmount} />
      ),
    });
  };

  const buyWithMobilePay = async () => {
    setModal({
      open: true,
      children: <MobilePayModal eventCard={eventCard} amount={ticketAmount} />,
    });
  };

  const getDiscount = async () => {
    if (promoCode !== "") {
      const data = {
        promoCode,
        eventCard: eventCard.id,
      };
      setLoading(true);
      const response = await verifyPromoCode(data);
      setLoading(false);
      console.log(response);
      if (response.success) {
        setDiscount(parseFloat(response.discount) / 100);
        setPromoSuccess(1);
      } else {
        setPromoSuccess(2);
      }
    }
  };

  const removePromoCode = () => {
    setPromoCode("");
    setDiscount(0);
    setPromoSuccess(0);
  };

  useEffect(() => {
    if (discount === 1) {
      handleBuyTicket(
        "free ticket",
        userInfo.user.wallet_address,
        "Binance Smart Chain"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  return (
    <div className={styles.container}>
      <h4 className="modal__title">{t("proceed to Pay")}</h4>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.btn_close}
        onClick={() => setModal({ open: false })}
      >
        <path
          d="M18 6L6 18"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className="order__summary">
        <p className="order__summary_title">{t("order summary")}</p>
        <div className="order__summary_item">
          <p className="order__summary_text">{eventCard.name}</p>
          <p className="order__summary_text">
            {Number((noDiscountPrice * ticketAmount).toFixed(2)).toString()}
            <FiatSymbol />
          </p>
        </div>
        <p className="order__summary_quantity">
          {t("quantity")}: {ticketAmount}
        </p>
        {!eventCard.tax_include && (
          <div className="order__summary_item">
            <p className="order__summary_text">Fees</p>
            <p className="order__summary_text">
              {Number(
                (
                  (noDiscountPrice * eventCard.tax * ticketAmount) /
                  100
                ).toFixed(2)
              ).toString()}
              <FiatSymbol />
            </p>
          </div>
        )}
        {promoSuccess === 1 && (
          <div className="order__summary_item">
            <p className="order__summary_text">
              Discount <span className={styles.code_text}>({promoCode})</span>{" "}
            </p>
            <p className={styles.discount_fiat}>
              -{" "}
              {eventCard.tax_include
                ? (Number(noDiscountPrice) * discount * ticketAmount).toFixed(2)
                : (
                    Number((1 + eventCard.tax / 100) * noDiscountPrice) *
                    discount *
                    ticketAmount
                  ).toFixed(2)}
              <FiatSymbol />
            </p>
          </div>
        )}
        <div className="divide"></div>
        <div className="order__summary_item">
          <p className="order__summary_text fontBold mb-0">
            {t("total")}
            {eventCard.tax_include && (
              <span className="text__small-time"> (INCL. Fees)</span>
            )}
          </p>
          <p className="order__summary_text fontBold mb-0">
            {eventCard.tax_include
              ? (
                  Number(noDiscountPrice) *
                  ticketAmount *
                  (1 - discount)
                ).toFixed(2)
              : (
                  Number((1 + eventCard.tax / 100) * noDiscountPrice) *
                  ticketAmount *
                  (1 - discount)
                ).toFixed(2)}
            <FiatSymbol />
          </p>
        </div>
      </div>
      {promoSuccess !== 1 ? (
        <div className={styles.discount_code}>
          <p className={styles.discount_text}>Have a discount code?</p>
          <div className={styles.discount_content}>
            <div className={styles.input_container}>
              <input
                type="text"
                placeholder="Enter discount code..."
                onChange={(e) => setPromoCode(e.target.value)}
                className={styles.discount_input}
                style={{ borderColor: promoSuccess === 2 && "red" }}
              />
              {promoSuccess === 2 && (
                <p className={styles.wrong_code}>
                  The code you entered is not a valid for the item in your
                  order.
                </p>
              )}
            </div>
            <button onClick={getDiscount} className={styles.btn_apply}>
              APPLY
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.discount__success}>
          <div className={styles.discount__success_right}>
            <img src="/img/icons/discount.svg" alt="discount" />
            <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: "#fff",
                  margin: 0,
                }}
              >
                {promoCode} <span style={{ fontWeight: 400 }}>applied</span>
              </p>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#FFFFFF54",
                  margin: 0,
                }}
              >
                {eventCard.tax_include
                  ? (Number(noDiscountPrice) * discount * ticketAmount).toFixed(
                      2
                    )
                  : (
                      Number((1 + eventCard.tax / 100) * noDiscountPrice) *
                      discount *
                      ticketAmount
                    ).toFixed(2)}{" "}
                <FiatSymbol />
                <span style={{ fontSize: 12 }}>(-{discount * 100}% off)</span>
              </p>
            </div>
          </div>
          <img
            src="/img/icons/red_trash.svg"
            onClick={removePromoCode}
            style={{ cursor: "pointer" }}
            alt="delete"
          />
        </div>
      )}
      <div className="terms__check">
        <div className="sign__group--checkbox">
          <input
            id="remember2"
            name="remember2"
            type="checkbox"
            checked={terms}
            onChange={() => setTerms(!terms)}
          />
          {locale === "es" ? (
            <label htmlFor="remember2" className="terms__text">
              He leído y estoy de acuerdo con los&nbsp;
              <span
                className="terms_and_conditions"
                onClick={() =>
                  setModal({
                    open: true,
                    children: (
                      <TermsConditionModal terms={eventCard.terms_conditions} />
                    ),
                  })
                }
              >
                términos y condiciones
              </span>
              &nbsp;de este producto
            </label>
          ) : (
            <label htmlFor="remember2" className="terms__text">
              I have read and agree with the&nbsp;
              <span
                className="terms_and_conditions"
                onClick={() =>
                  setModal({
                    open: true,
                    children: (
                      <TermsConditionModal terms={eventCard.terms_conditions} />
                    ),
                  })
                }
              >
                terms and conditions
              </span>
              &nbsp;of this product
            </label>
          )}
        </div>
      </div>
      {eventCard.collection.id === process.env.REACT_APP_SWISH_COLLECTION && (
        <button
          disabled={!terms}
          style={{ opacity: terms ? 1 : 0.3 }}
          className="asset__btn swish__btn"
          onClick={buyWithSwish}
        >
          <div>
            <img alt="swish" src="/img/card/swish.png" width={100} />
          </div>
        </button>
      )}
      {eventCard.collection.id ===
        process.env.REACT_APP_MOBILE_PAY_COLLECTION && (
        <button
          disabled={!terms}
          style={{ opacity: terms ? 1 : 0.3 }}
          className="asset__btn swish__btn"
          onClick={buyWithMobilePay}
        >
          <div>
            <img alt="mobile pay" src="/img/card/mobile_pay.png" width={160} />
          </div>
        </button>
      )}
      <button
        disabled={!terms}
        style={{ opacity: terms ? 1 : 0.3 }}
        className="asset__btn"
        onClick={buyWithCC}
      >
        <img
          alt="credit"
          src="/img/card/credit-card.png"
          style={{ marginRight: "14px" }}
        />
        {t("buy with credit card")}
      </button>

      {eventCard.collection.id !== process.env.REACT_APP_ZAMNA_COLLECTION && (
        <div style={{ opacity: terms ? 1 : 0.3 }}>
          <p className={styles.text_or}>{t("or")}</p>

          <button
            className={styles.btn_crypto}
            onClick={() => buyWithUSDT("Metamask")}
          >
            <img src="/img/metamask-logo.png" alt="metamask" height={30} />
            <span
              style={{
                marginLeft: 10,
              }}
            >
              Metamask
            </span>
          </button>

          <button
            className={styles.btn_crypto}
            onClick={() => buyWithUSDT("Bitkeep")}
          >
            <img
              src="/img/bitget-logo.png"
              alt="bitkeep"
              className={styles.bitget_img}
            />
            <span
              style={{
                marginLeft: 12,
              }}
            >
              Bitget Wallet
            </span>
          </button>

          <button
            className={styles.btn_crypto}
            // onClick={() => buyWithUSDT("okxwallet")}
            onClick={() =>
              setModal({
                open: true,
                children: <OKXModal eventCard={eventCard} />,
              })
            }
          >
            <img
              src="/img/okx.png"
              alt="bitkeep"
              className={styles.bitget_img}
            />
            <span
              style={{
                marginLeft: 12,
              }}
            >
              OKX Wallet
            </span>
          </button>

          <button
            className={styles.btn_crypto}
            onClick={() => buyWithBKSWallet()}
          >
            <img
              src="/img/logo-without-text.png"
              alt="logo"
              height={30}
              style={{ marginRight: "5px" }}
            />
            <span
              style={{
                marginLeft: 5,
              }}
            >
              BKS wallet
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default TicketBuyModal;
