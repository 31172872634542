import { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";

const Fiat = ({ price, currency }) => {
  const {
    country,
    rateEURvsUSD,
    rateGBPvsUSD,
    rateTRYvsUSD,
    rateINRvsUSD,
    rateCLPvsUSD,
    rateDKKvsUSD,
    rateSEKvsUSD,
    rateMXNvsUSD,
  } = useAppContext();
  const [fiat, setFiat] = useState(Number(price));
  useEffect(() => {
    switch (country) {
      case "TR":
        if (currency === "USD") {
          setFiat(Number(price) / rateTRYvsUSD);
        } else if (currency === "TRY") {
          setFiat(Number(price));
        } else if (currency === "INR") {
          setFiat((Number(price) * rateINRvsUSD) / rateTRYvsUSD);
        } else if (currency === "DKK") {
          setFiat((Number(price) * rateDKKvsUSD) / rateTRYvsUSD);
        } else if (currency === "SEK") {
          setFiat((Number(price) * rateSEKvsUSD) / rateTRYvsUSD);
        } else if (currency === "CLP") {
          setFiat((Number(price) * rateCLPvsUSD) / rateTRYvsUSD);
        } else if (currency === "GBP") {
          setFiat((Number(price) * rateGBPvsUSD) / rateTRYvsUSD);
        } else if (currency === "MXN") {
          setFiat((Number(price) * rateMXNvsUSD) / rateTRYvsUSD);
        } else {
          setFiat((Number(price) * rateEURvsUSD) / rateTRYvsUSD);
        }
        break;
      case "GB":
        if (currency === "USD") {
          setFiat(Number(price) / rateGBPvsUSD);
        } else if (currency === "INR") {
          setFiat((Number(price) * rateINRvsUSD) / rateGBPvsUSD);
        } else if (currency === "TRY") {
          setFiat((Number(price) * rateTRYvsUSD) / rateGBPvsUSD);
        } else if (currency === "DKK") {
          setFiat((Number(price) * rateDKKvsUSD) / rateGBPvsUSD);
        } else if (currency === "SEK") {
          setFiat((Number(price) * rateSEKvsUSD) / rateGBPvsUSD);
        } else if (currency === "CLP") {
          setFiat((Number(price) * rateCLPvsUSD) / rateGBPvsUSD);
        } else if (currency === "GBP") {
          setFiat(Number(price));
        } else if (currency === "MXN") {
          setFiat((Number(price) * rateMXNvsUSD) / rateGBPvsUSD);
        } else {
          setFiat((Number(price) * rateEURvsUSD) / rateGBPvsUSD);
        }
        break;
      case "US":
        if (currency === "USD") {
          setFiat(Number(price));
        } else if (currency === "INR") {
          setFiat(Number(price) * rateINRvsUSD);
        } else if (currency === "TRY") {
          setFiat(Number(price) * rateTRYvsUSD);
        } else if (currency === "CLP") {
          setFiat(Number(price) * rateCLPvsUSD);
        } else if (currency === "DKK") {
          setFiat(Number(price) * rateDKKvsUSD);
        } else if (currency === "SEK") {
          setFiat(Number(price) * rateSEKvsUSD);
        } else if (currency === "GBP") {
          setFiat(Number(price) * rateGBPvsUSD);
        } else if (currency === "MXN") {
          setFiat(Number(price) * rateMXNvsUSD);
        } else {
          setFiat(Number(price) * rateEURvsUSD);
        }
        break;
      case "IN":
        if (currency === "INR") {
          setFiat(Number(price));
        } else if (currency === "USD") {
          setFiat(Number(price) / rateINRvsUSD);
        } else if (currency === "TRY") {
          setFiat((Number(price) * rateTRYvsUSD) / rateINRvsUSD);
        } else if (currency === "CLP") {
          setFiat((Number(price) * rateCLPvsUSD) / rateINRvsUSD);
        } else if (currency === "DKK") {
          setFiat((Number(price) * rateDKKvsUSD) / rateINRvsUSD);
        } else if (currency === "SEK") {
          setFiat((Number(price) * rateSEKvsUSD) / rateINRvsUSD);
        } else if (currency === "GBP") {
          setFiat((Number(price) * rateGBPvsUSD) / rateINRvsUSD);
        } else if (currency === "MXN") {
          setFiat((Number(price) * rateMXNvsUSD) / rateINRvsUSD);
        } else {
          setFiat((Number(price) * rateEURvsUSD) / rateINRvsUSD);
        }
        break;
      case "EU":
        if (currency === "USD") {
          setFiat(Number(price) / rateEURvsUSD);
        } else if (currency === "INR") {
          setFiat((Number(price) * rateINRvsUSD) / rateEURvsUSD);
        } else if (currency === "TRY") {
          setFiat((Number(price) * rateTRYvsUSD) / rateEURvsUSD);
        } else if (currency === "CLP") {
          setFiat((Number(price) * rateCLPvsUSD) / rateEURvsUSD);
        } else if (currency === "DKK") {
          setFiat((Number(price) * rateDKKvsUSD) / rateEURvsUSD);
        } else if (currency === "SEK") {
          setFiat((Number(price) * rateSEKvsUSD) / rateEURvsUSD);
        } else if (currency === "GBP") {
          setFiat((Number(price) * rateGBPvsUSD) / rateEURvsUSD);
        } else if (currency === "MXN") {
          setFiat((Number(price) * rateMXNvsUSD) / rateEURvsUSD);
        } else {
          setFiat(Number(price));
        }
        break;
      case "CL":
        if (currency === "CLP") {
          setFiat(Number(price));
        } else if (currency === "USD") {
          setFiat(Number(price) / rateCLPvsUSD);
        } else if (currency === "TRY") {
          setFiat((Number(price) * rateTRYvsUSD) / rateCLPvsUSD);
        } else if (currency === "INR") {
          setFiat((Number(price) * rateINRvsUSD) / rateCLPvsUSD);
        } else if (currency === "DKK") {
          setFiat((Number(price) * rateDKKvsUSD) / rateCLPvsUSD);
        } else if (currency === "SEK") {
          setFiat((Number(price) * rateSEKvsUSD) / rateCLPvsUSD);
        } else if (currency === "GBP") {
          setFiat((Number(price) * rateGBPvsUSD) / rateCLPvsUSD);
        } else if (currency === "MXN") {
          setFiat((Number(price) * rateMXNvsUSD) / rateCLPvsUSD);
        } else {
          setFiat((Number(price) * rateEURvsUSD) / rateCLPvsUSD);
        }
        break;
      case "DK":
        if (currency === "DKK") {
          setFiat(Number(price));
        } else if (currency === "USD") {
          setFiat(Number(price) / rateDKKvsUSD);
        } else if (currency === "TRY") {
          setFiat((Number(price) * rateTRYvsUSD) / rateDKKvsUSD);
        } else if (currency === "INR") {
          setFiat((Number(price) * rateINRvsUSD) / rateDKKvsUSD);
        } else if (currency === "SEK") {
          setFiat((Number(price) * rateSEKvsUSD) / rateDKKvsUSD);
        } else if (currency === "CLP") {
          setFiat((Number(price) * rateCLPvsUSD) / rateDKKvsUSD);
        } else if (currency === "GBP") {
          setFiat((Number(price) * rateGBPvsUSD) / rateDKKvsUSD);
        } else if (currency === "MXN") {
          setFiat((Number(price) * rateMXNvsUSD) / rateDKKvsUSD);
        } else {
          setFiat((Number(price) * rateEURvsUSD) / rateDKKvsUSD);
        }
        break;
      case "SE":
        if (currency === "SEK") {
          setFiat(Number(price));
        } else if (currency === "USD") {
          setFiat(Number(price) / rateSEKvsUSD);
        } else if (currency === "TRY") {
          setFiat((Number(price) * rateTRYvsUSD) / rateSEKvsUSD);
        } else if (currency === "INR") {
          setFiat((Number(price) * rateINRvsUSD) / rateSEKvsUSD);
        } else if (currency === "DKK") {
          setFiat((Number(price) * rateDKKvsUSD) / rateSEKvsUSD);
        } else if (currency === "CLP") {
          setFiat((Number(price) * rateCLPvsUSD) / rateSEKvsUSD);
        } else if (currency === "GBP") {
          setFiat((Number(price) * rateGBPvsUSD) / rateSEKvsUSD);
        } else if (currency === "MXN") {
          setFiat((Number(price) * rateMXNvsUSD) / rateSEKvsUSD);
        } else {
          setFiat((Number(price) * rateEURvsUSD) / rateSEKvsUSD);
        }
        break;
      case "MX":
        if (currency === "MXN") {
          setFiat(Number(price));
        } else if (currency === "USD") {
          setFiat(Number(price) / rateMXNvsUSD);
        } else if (currency === "TRY") {
          setFiat((Number(price) * rateTRYvsUSD) / rateMXNvsUSD);
        } else if (currency === "INR") {
          setFiat((Number(price) * rateINRvsUSD) / rateMXNvsUSD);
        } else if (currency === "DKK") {
          setFiat((Number(price) * rateDKKvsUSD) / rateMXNvsUSD);
        } else if (currency === "CLP") {
          setFiat((Number(price) * rateCLPvsUSD) / rateMXNvsUSD);
        } else if (currency === "GBP") {
          setFiat((Number(price) * rateGBPvsUSD) / rateMXNvsUSD);
        } else if (currency === "SEK") {
          setFiat((Number(price) * rateSEKvsUSD) / rateMXNvsUSD);
        } else {
          setFiat((Number(price) * rateEURvsUSD) / rateMXNvsUSD);
        }
        break;
      default:
        setFiat(Number(price));
        break;
    }
  }, [
    country,
    rateEURvsUSD,
    rateGBPvsUSD,
    rateTRYvsUSD,
    rateINRvsUSD,
    rateCLPvsUSD,
    rateDKKvsUSD,
    rateSEKvsUSD,
    rateMXNvsUSD,
    price,
    currency,
  ]);
  return <>{parseFloat(fiat.toFixed())}</>;
};

export default Fiat;
