import { Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import TimeAgo from "javascript-time-ago";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { provider } from "web3-core";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import Hotjar from "@hotjar/browser";
import ReactGA from "react-ga";

import AppContextProvider from "./context/AppContext";
import UserContextProvider from "./context/UserContext";

import View from "./views";
import CookieConsent from "./components/cookieconsent";
import { client_id } from "./utils/auth_secret";

import "./App.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const siteId = 3893005;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

ReactGA.initialize(process.env.REACT_APP_GA as string);

function getLibrary(provider: provider) {
  return new Web3(provider);
}

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId={client_id}>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={6000}
          placement="top-right"
        >
          <CookiesProvider>
            <AppContextProvider>
              <UserContextProvider>
                <Web3ReactProvider getLibrary={getLibrary}>
                  <Suspense fallback={null}>
                    <BrowserRouter>
                      <View />
                    </BrowserRouter>
                    <CookieConsent />
                  </Suspense>
                </Web3ReactProvider>
              </UserContextProvider>
            </AppContextProvider>
          </CookiesProvider>
        </ToastProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
