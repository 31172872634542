import { useEffect } from "react";
import {
  Route,
  Routes,
  useSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Cookies from "js-cookie";

import CustomModalComponent from "../components/custom_modals";
import Loading from "../components/Loading";
// import RecommendTwoFactorModal from "../components/custom_modals/recommend_modal";
import { useAppContext } from "../context/AppContext";
import { useUserContext } from "../context/UserContext";
import routes from "../routes";
import Footer from "./layout/footer";
import Header from "./layout/header";
import { getPermissionByEmail } from "../api/permission";
import { getTwoFactorStatus } from "../api/twofactor";
import { getRates } from "../api/rate";

const View = () => {
  const {
    loading,
    setCountry,
    modal,
    setModal,
    setMedia,
    scrollItem,
    setRateEURvsUSD,
    setRateGBPvsUSD,
    setRateTRYvsUSD,
    setRateINRvsUSD,
    setRateCLPvsUSD,
    setRateDKKvsUSD,
    setRateSEKvsUSD,
    setRateMXNvsUSD,
    setCarouselItem,
    setPermission,
    // twoStepStatus,
    setTwoStepStatus,
    setTrackId,
  } = useAppContext();
  const [searchParams] = useSearchParams();
  const { setUserInfo, userInfo } = useUserContext();
  const location = useLocation();

  const { i18n } = useTranslation();

  useEffect(() => {
    resetItem();
    window.addEventListener(
      "resize",
      function(event) {
        resetItem();
      },
      true
    );
  });

  const getRoutes = () =>
    routes.map((prop, key) => <Route key={key} {...prop} />);

  const navigate = useNavigate();

  const resetItem = () => {
    if (window.innerWidth >= 1224) {
      setCarouselItem(4);
    } else if (window.innerWidth >= 451) {
      setCarouselItem(
        Math.round(((window.innerWidth - 30) / 300) * 10) / 10 - 0.1
      );
    } else {
      setCarouselItem(1);
    }
  };

  const timeout = 3600 * 1000;

  const onIdle = () => {
    // if (Boolean(cookies.userInfo)) removeCookie("userInfo");
    Cookies.remove("userInfo");
    setUserInfo(null);
    navigate("/signin");
    setModal({
      open: false,
    });
  };

  const onActive = () => {
    console.log("active");
  };

  const setLanguage = () => {
    const locale = navigator.language.split("-")[0];
    console.log(locale);
    if (
      locale === "es" ||
      locale === "tr" ||
      locale === "da" ||
      locale === "it"
    ) {
      i18n.changeLanguage(locale);
    } else {
      i18n.changeLanguage("en");
    }
  };

  const getIPaddress = async () => {
    try {
      const request = await fetch(
        "https://ipinfo.io/json?token=dfbc22df7d8da7"
      );
      const json = await request.json();
      if (
        json.country === "TR" ||
        json.country === "IN" ||
        json.country === "CL" ||
        json.country === "DK" ||
        json.country === "SE" ||
        json.country === "MX" ||
        json.country === "GB"
      ) {
        setCountry(json.country);
      } else if (json.timezone.search("Europe") !== -1) {
        setCountry("EU");
      } else {
        setCountry("US");
      }
    } catch (error) {
      setCountry("EU");
    }
  };

  const getPermission = async () => {
    if (userInfo?.user?.email) {
      const permission = await getPermissionByEmail({
        email: userInfo?.user?.email,
      });
      if (permission?.allow) {
        setPermission(permission);
      }
    }
  };

  const getStatus = async () => {
    if (userInfo?.user?.id) {
      const twoFactorStatus = await getTwoFactorStatus({
        userId: userInfo.user.id,
      });
      if (twoFactorStatus?.status) {
        setTwoStepStatus(twoFactorStatus?.status);
      }
    }
  };

  // const recommend2FA = () => {
  //   if (userInfo && !twoStepStatus && !localStorage.getItem("recommend2FA")) {
  //     setModal({
  //       open: true,
  //       children: <RecommendTwoFactorModal />,
  //     });
  //     localStorage.setItem("recommend2FA", "true");
  //   }
  // };

  const setRate = async () => {
    const res = await getRates();
    setRateEURvsUSD(Number(res.rates.EUR));
    setRateTRYvsUSD(Number(res.rates.TRY));
    setRateINRvsUSD(Number(res.rates.INR));
    setRateGBPvsUSD(Number(res.rates.GBP));
    setRateCLPvsUSD(Number(res.rates.CLP));
    setRateDKKvsUSD(Number(res.rates.DKK));
    setRateSEKvsUSD(Number(res.rates.SEK));
    setRateMXNvsUSD(Number(res.rates.MXN));
  };

  useIdleTimer({
    timeout,
    onActive,
    onIdle,
  });

  useEffect(() => {
    setRate();
    getIPaddress();
    setLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPermission();
    getStatus();
    // recommend2FA();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    const media = searchParams.get("media");
    const trackId = searchParams.get("trackId");
    if (media) {
      setMedia(media);
    }
    if (trackId) {
      setTrackId(trackId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    (scrollItem.current as HTMLDivElement).scrollIntoView({
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <div className="body__container">
        {location.pathname === "/" && (
          <div className="back__logo">
            <LazyLoadImage
              src="/img/icons/background_logo.svg"
              alt="background"
              className="background__logo"
            />
          </div>
        )}
        <Header scrollItem={scrollItem} />
        <Routes>{getRoutes()}</Routes>
        <Footer />
      </div>
      <CustomModalComponent
        open={modal.open}
        children={modal.children}
        onClose={(event: any, reason: any) =>
          setModal({ ...modal, open: false })
        }
      />
      <Loading open={loading} />
    </>
  );
};

export default View;
