import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import FiatSymbol from "../FiatSymbol";
import timeZones from "../../utils/timeZone";
import JoditEditor from "jodit-react";
import "jodit";
import "jodit/build/jodit.min.css";

const ItemDetail = ({
  validations,
  values,
  handleChange,
  toggleFixedPeriod,
  toggleTaxInclude,
}) => {
  const { t } = useTranslation();
  const nameRef = useRef(null);
  const locationRef = useRef(null);
  const dateRef = useRef(null);
  const endDateRef = useRef(null);
  const timeZoneRef = useRef(null);
  const stopTimeRef = useRef(null);
  const totalTicketsRef = useRef(null);
  const scanRef = useRef(null);
  const priceRef = useRef(null);
  const taxRef = useRef(null);
  const venueRef = useRef(null);
  const descriptionRef = useRef(null);
  const termsRef = useRef(null);
  const borderStyle = (str) => {
    return str === "has-empty"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };
  const editorConfig = {
    readonly: false,
    toolbar: false,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    uploader: {
      insertImageAsBase64URI: false,
    },
    height: 100,
  };

  useEffect(() => {
    if (validations.name === "has-empty") {
      nameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.location === "has-empty") {
      nameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.date === "has-empty") {
      dateRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.end_date === "has-empty") {
      endDateRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.time_zone === "has-empty") {
      timeZoneRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.stop_time === "has-empty") {
      stopTimeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.total_tickets === "has-empty") {
      totalTicketsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.scan === "has-error") {
      scanRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.price === "has-empty") {
      priceRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.tax === "has-error") {
      taxRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.venue_description === "has-empty") {
      venueRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.description === "has-empty") {
      descriptionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.terms_conditions === "has-empty") {
      termsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [validations]);
  return (
    <div className="cr__evcard-subItem">
      <h4 className="cr__evcard--subtitle">
        <span></span>
        {t("item details")}
      </h4>
      <div className="cr__evcard-rowParent">
        <div
          className="sign__group cr__evcard-100"
          ref={nameRef}
          style={{ paddingLeft: 0 }}
        >
          <p className="cr__evcard-labelTitle">{t("name")}*</p>
          <input
            id="name"
            type="text"
            name="name"
            className="sign__input"
            value={values.name}
            onChange={(e) => handleChange("name", e.target.value)}
            style={borderStyle(validations.name)}
          />
          {validations.name === "has-empty" && (
            <span className="text-error">{t("name required")}*</span>
          )}
        </div>
      </div>
      {values.category !== "Category2" && (
        <div className="cr__evcard-rowParent">
          <div className="sign__group cr__evcard-50" ref={locationRef}>
            <p className="cr__evcard-labelTitle">{t("location")}*</p>
            <input
              id="location"
              type="text"
              name="location"
              className="sign__input"
              value={values.location}
              onChange={(e) => handleChange("location", e.target.value)}
              style={borderStyle(validations.location)}
            />
            {validations.location === "has-empty" && (
              <span className="text-error">{t("location required")}*</span>
            )}
          </div>
          {values.category !== "Category3" ? (
            <div className="sign__group cr__evcard-50" ref={dateRef}>
              <p className="cr__evcard-labelTitle">{t("date and time")}*</p>
              <input
                id="date"
                type="datetime-local"
                name="date"
                className="sign__input"
                value={values.date}
                onChange={(e) => handleChange("date", e.target.value)}
                style={borderStyle(validations.date)}
              />

              {validations.date === "has-empty" && (
                <span className="text-error">{t("date required")}*</span>
              )}
            </div>
          ) : (
            <div
              className="sign__group cr__evcard-50"
              style={{
                position: "relative",
                justifyContent: "space-between",
              }}
            >
              <div className="sign__group cr__evcard-70">
                <p className="cr__evcard-labelTitle">{t("Benefit Period")}*</p>
                <input
                  id="period"
                  type="number"
                  name="date"
                  className="sign__input"
                  value={values.period}
                  onChange={(e) => handleChange("period", e.target.value)}
                />
              </div>
              <div className="sign__group cr__evcard-30">
                <p className="cr__evcard-labelTitle">
                  {/* {t("select currency")} */}
                </p>
                <select
                  id="period_unit"
                  name="period_unit"
                  className="sign__select"
                  value={values.period_unit}
                  onChange={(e) => handleChange("period_unit", e.target.value)}
                >
                  <option value="Year(s)">{t("year")}</option>
                  <option value="Month(s)">{t("month")}</option>
                  <option value="Day(s)">{t("day")}</option>
                </select>
                <img
                  src="/img/icons/arrow-down.svg"
                  alt="arrow-down"
                  className="cr__evcard-selectArrow"
                  style={{ top: 30 }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {values.category === "Category3" && (
        <div className="cr__evcard-rowParent">
          <div className="sign__group cr__evcard-50">
            <p className="cr__evcard-labelTitle">{t("start date and time")}*</p>
            <input
              id="date"
              type="datetime-local"
              name="date"
              className="sign__input"
              value={values.date}
              onChange={(e) => handleChange("date", e.target.value)}
              style={borderStyle(validations.date)}
            />

            {validations.date === "has-empty" && (
              <span className="text-error">{t("start date required")}*</span>
            )}
          </div>
          <div className="sign__group cr__evcard-50" ref={endDateRef}>
            <p className="cr__evcard-labelTitle">{t("end date and time")}*</p>
            <input
              id="end_date"
              type="datetime-local"
              name="end_date"
              className="sign__input"
              value={values.end_date}
              onChange={(e) => handleChange("end_date", e.target.value)}
              style={borderStyle(validations.end_date)}
            />

            {validations.end_date === "has-empty" && (
              <span className="text-error">{t("end date required")}*</span>
            )}
          </div>
        </div>
      )}
      {values.category !== "Category2" && (
        <div className="cr__evcard-rowParent">
          <div className="sign__group cr__evcard-50" ref={timeZoneRef}>
            <p className="cr__evcard-labelTitle">{t("time zone")}</p>
            <select
              id="time_zone"
              name="time_zone"
              className="sign__select"
              value={values.time_zone}
              onChange={(e) => handleChange("time_zone", e.target.value)}
            >
              {timeZones.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
            <img
              src="/img/icons/arrow-down.svg"
              alt="arrow down"
              className="cr__evcard-selectArrow"
            />
            {validations.time_zone === "has-empty" && (
              <span className="text-error">{t("time zone required")}*</span>
            )}
          </div>
          <div className="sign__group cr__evcard-50" ref={stopTimeRef}>
            <p className="cr__evcard-labelTitle">{t("sales stop time")}*</p>
            <input
              id="stop_time"
              type="datetime-local"
              name="stop_time"
              className="sign__input"
              value={values.stop_time}
              onChange={(e) => handleChange("stop_time", e.target.value)}
              style={borderStyle(validations.stop_time)}
            />

            {validations.stop_time === "has-empty" && (
              <span className="text-error">
                {t("sales stop time required")}*
              </span>
            )}
          </div>
        </div>
      )}
      <div className="cr__evcard-rowParent">
        <div className="sign__group cr__evcard-50" ref={totalTicketsRef}>
          <p className="cr__evcard-labelTitle">
            {values.category === "Category1"
              ? t("number of copies")
              : t("number of items")}
            *
          </p>
          <input
            id="total_tickets"
            type="number"
            name="total_tickets"
            className="sign__input"
            value={values.total_tickets}
            onChange={(e) => handleChange("total_tickets", e.target.value)}
            style={borderStyle(validations.total_tickets)}
          />
          {validations.total_tickets === "has-empty" && (
            <span className="text-error">
              {t("number of total tickets required")}*
            </span>
          )}
        </div>
        <div
          className="sign__group cr__evcard-50"
          style={{ position: "relative" }}
          ref={scanRef}
        >
          <p className="cr__evcard-labelTitle">{t("number of scan")}</p>
          <input
            id="scan"
            type="number"
            name="scan"
            className="sign__input"
            value={values.scan}
            onChange={(e) => handleChange("scan", e.target.value)}
            style={borderStyle(validations.scan)}
          />
          {validations.scan === "has-error" && (
            <span className="text-error">{t("scan required")}*</span>
          )}
        </div>
      </div>
      <div className="cr__evcard-rowParent">
        <div
          className="sign__group cr__evcard-50"
          style={{ position: "relative" }}
          ref={priceRef}
        >
          <p className="cr__evcard-labelTitle">{t("price")}*</p>
          <input
            id="price"
            type="number"
            name="price"
            className="sign__input"
            value={values.price}
            onChange={(e) => handleChange("price", e.target.value)}
            style={borderStyle(validations.price)}
          />
          <div className="cr__evcard-price-symbol">
            <FiatSymbol currency={values.currency} />
          </div>
          {validations.price === "has-empty" && (
            <span className="text-error">{t("price required")}*</span>
          )}
        </div>
        <div
          className="sign__group cr__evcard-50"
          style={{
            position: "relative",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div
            className="sign__group cr__evcard-50"
            style={{ position: "relative" }}
          >
            <p className="cr__evcard-labelTitle">{t("currency")}</p>
            <select
              id="currency"
              name="currency"
              className="sign__select"
              value={values.currency}
              onChange={(e) => handleChange("currency", e.target.value)}
            >
              <option value="USD">{t("USD")}</option>
              <option value="EUR">{t("EUR")}</option>
              <option value="INR">{t("INR")}</option>
              <option value="TRY">{t("TRY")}</option>
              <option value="GBP">{t("GBP")}</option>
              <option value="CLP">{t("CLP")}</option>
              <option value="DKK">{t("DKK")}</option>
              <option value="SEK">{t("SEK")}</option>
              <option value="MXN">{t("MXN")}</option>
            </select>
            <img
              src="/img/icons/arrow-down.svg"
              alt="arrow down"
              className="cr__evcard-selectArrow"
            />
          </div>
          <div
            className="sign__group cr__evcard-50"
            style={{ position: "relative" }}
            ref={taxRef}
          >
            <p className="cr__evcard-labelTitle">{t("tax")}</p>
            <input
              id="tax"
              type="number"
              name="tax"
              className="sign__input"
              value={values.tax}
              onChange={(e) => handleChange("tax", e.target.value)}
            />
            <div className="cr__evcard-price-symbol">%</div>
            {validations.tax === "has-error" && (
              <span className="text-error">{t("tax error")}*</span>
            )}
          </div>
        </div>
      </div>
      <div className="cr__evcard-rowParent">
        <div className="cr__evcard-50">
          <div className="sign__group sign__group--checkbox fixed__period">
            <input
              id="tax_include"
              name="tax_include"
              type="checkbox"
              onClick={toggleTaxInclude}
            />
            <label
              htmlFor="tax_include"
              style={{ color: "#fff", letterSpacing: "0.03em" }}
            >
              {t("tax include")}
            </label>
          </div>
        </div>
        {values.category === "Category3" && (
          <div className="cr__evcard-50">
            <div className="sign__group sign__group--checkbox fixed__period">
              <input
                id="type5"
                name="type5"
                type="checkbox"
                onClick={toggleFixedPeriod}
              />
              <label
                htmlFor="type5"
                style={{ color: "#fff", letterSpacing: "0.03em" }}
              >
                {t("fixed period")}
              </label>
            </div>
          </div>
        )}
      </div>
      {values.category !== "Category2" && (
        <div className="cr__evcard-100" ref={venueRef}>
          <p className="cr__evcard-labelTitle">{t("venue description")}*</p>
          <div className="text-editor-wrapper-article">
            <JoditEditor
              config={editorConfig}
              onBlur={(newContent) =>
                handleChange("venue_description", newContent)
              }
            />
          </div>
          {validations.venue_description === "has-empty" && (
            <span className="text-error">
              {t("venue description required")}*
            </span>
          )}
        </div>
      )}
      {values.category === "Category2" && (
        <div className="cr__evcard-100" ref={descriptionRef}>
          <p className="cr__evcard-labelTitle">{t("general description")}*</p>
          <div className="text-editor-wrapper-article">
            <JoditEditor
              config={editorConfig}
              onBlur={(newContent) => handleChange("description", newContent)}
            />
          </div>
          {validations.description === "has-empty" && (
            <span className="text-error">{t("description required")}*</span>
          )}
        </div>
      )}
      <div className="cr__evcard-100" ref={termsRef}>
        <p className="cr__evcard-labelTitle">{t("terms and conditions")}*</p>
        <div className="text-editor-wrapper-article">
          <JoditEditor
            config={editorConfig}
            onBlur={(newContent) =>
              handleChange("terms_conditions", newContent)
            }
          />
        </div>
        {validations.terms_conditions === "has-empty" && (
          <span className="text-error">
            {t("terms and conditions required")}*
          </span>
        )}
      </div>
    </div>
  );
};

export default ItemDetail;
