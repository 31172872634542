import React, { useMemo, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";

import { useUserContext } from "../../context/UserContext";
import { useAppContext } from "../../context/AppContext";
import { getEventPrice, getLikesNumber } from "../../utils";
import AddonsModal from "../custom_modals/addons_modal";
import Fiat from "../Fiat";
import FiatSymbol from "../FiatSymbol";
import EventCountDown from "../countdown";
import EventAvatar from "./EventAvatar";
import LinkShare from "../link_share";
import styles from "./index.module.css";

const EventCardInfo = ({
  eventCard,
  onClickLike,
  showFloorMap,
  isSold,
  handleBuyTicket,
  ticketAmount,
  setTicketAmount,
  fixedTime,
  saleStop,
}) => {
  const { userInfo } = useUserContext();
  const recaptchaRef = useRef();
  const shareElement = useRef();
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const likeNumber = useMemo(() => {
    return getLikesNumber(eventCard);
  }, [eventCard]);
  const [captcha, setCaptcha] = useState(true);
  const [share, setShare] = useState(false);
  const { setModal } = useAppContext();
  const currentTime = new Date();
  const timeZoneDiff =
    (currentTime.getTimezoneOffset() / 60 + eventCard.time_zone) * 3600 * 1000;

  const showAddons = () => {
    setModal({
      open: true,
      children: <AddonsModal addons={JSON.parse(eventCard.addons)} />,
    });
  };

  const onChangeCaptcha = (e) => {
    setCaptcha(e);
  };

  const timeIsClose = () => {
    const currentTime = new Date();
    const timeZoneDiff =
      (currentTime.getTimezoneOffset() / 60 + eventCard.time_zone) *
      3600 *
      1000;
    if (
      eventCard.stop_time !== null &&
      currentTime.getTime() + timeZoneDiff >
        new Date(eventCard.stop_time).getTime()
    ) {
      return true;
    }
    return false;
  };

  const buttonText = () => {
    const currentTime = new Date();
    const timeZoneDiff =
      (currentTime.getTimezoneOffset() / 60 + eventCard.time_zone) *
      3600 *
      1000;
    let text = eventCard.sale_stop
      ? t("coming soon")
      : eventCard.total_tickets <= eventCard.buy_count ||
        (eventCard.stop_time !== null &&
          currentTime.getTime() + timeZoneDiff >
            new Date(eventCard.stop_time).getTime())
      ? t("sold out")
      : eventCard.sale_start_time !== null &&
        currentTime.getTime() + timeZoneDiff <
          new Date(eventCard.sale_start_time).getTime()
      ? t("coming soon")
      : userInfo?.user?.user_type === "CASHIER ADMIN"
      ? "Cash Sale"
      : t("buy");
    return text;
  };

  const isAvailable = () => {
    if (
      !isSold &&
      !eventCard.sale_stop &&
      !timeIsClose() &&
      (eventCard.sale_start_time === null ||
        currentTime.getTime() + timeZoneDiff >
          new Date(eventCard.sale_start_time).getTime()) &&
      eventCard.deleted !== 1 &&
      captcha
    )
      return true;
    else return false;
  };

  const handleOutsideClick = (event) => {
    if (shareElement.current && !shareElement.current.contains(event.target)) {
      setShare(false);
    }
  };

  useEffect(() => {
    // Attach the global click event listener
    document.addEventListener("click", handleOutsideClick);

    // Return a cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className={styles.assets__container}>
      <EventAvatar eventCard={eventCard} />
      <div className={styles.asset__info}>
        <div className={styles.asset__info_title}>
          <p className={styles.asset__name}>{eventCard.name}</p>
        </div>
        <div className={styles.like__share}>
          <button className={styles.like__btn} onClick={onClickLike}>
            {userInfo &&
            eventCard &&
            eventCard.likes_number &&
            eventCard.likes_number.includes(userInfo.user.id) ? (
              <img src="/img/icons/liked-thumbs-up.svg" alt="data" />
            ) : (
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.83073 9.98755L9.16406 2.48755C9.8271 2.48755 10.463 2.75094 10.9318 3.21978C11.4007 3.68862 11.6641 4.32451 11.6641 4.98755V8.32088H16.3807C16.6223 8.31815 16.8616 8.36797 17.082 8.4669C17.3025 8.56582 17.4987 8.71149 17.6573 8.89381C17.8158 9.07613 17.9328 9.29073 18.0002 9.52276C18.0675 9.75478 18.0837 9.99868 18.0474 10.2375L16.8974 17.7375C16.8371 18.135 16.6353 18.4972 16.329 18.7576C16.0227 19.0179 15.6327 19.1588 15.2307 19.1542H5.83073M5.83073 9.98755V19.1542M5.83073 9.98755H3.33073C2.8887 9.98755 2.46478 10.1631 2.15222 10.4757C1.83966 10.7883 1.66406 11.2122 1.66406 11.6542V17.4875C1.66406 17.9296 1.83966 18.3535 2.15222 18.6661C2.46478 18.9786 2.8887 19.1542 3.33073 19.1542H5.83073"
                  stroke="white"
                  strokeOpacity="0.66"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {userInfo &&
            eventCard &&
            eventCard.likes_number &&
            eventCard.likes_number.includes(userInfo.user.id) ? (
              <p style={{ margin: 0, color: "#6A4DFD" }}>Like</p>
            ) : (
              <p style={{ margin: "0 0 0 8px" }}>Like</p>
            )}
          </button>
          <div style={{ position: "relative" }}>
            <button
              onClick={() => setShare(!share)}
              className={styles.share__btn}
              ref={shareElement}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.33594 10.8208V17.4875C3.33594 17.9295 3.51153 18.3534 3.82409 18.666C4.13665 18.9785 4.56058 19.1541 5.0026 19.1541H15.0026C15.4446 19.1541 15.8686 18.9785 16.1811 18.666C16.4937 18.3534 16.6693 17.9295 16.6693 17.4875V10.8208"
                  stroke="white"
                  strokeOpacity="0.66"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.3307 5.82088L9.9974 2.48755L6.66406 5.82088"
                  stroke="white"
                  strokeOpacity="0.66"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 2.48755V13.3209"
                  stroke="white"
                  strokeOpacity="0.66"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p style={{ margin: "0 0 0 8px" }}>Share</p>
            </button>
            <div
              className={styles.share__container}
              style={{ display: share ? "block" : "none" }}
            >
              <LinkShare
                url={`${process.env.REACT_APP_SITE_URL}/event/eventcard/${eventCard.id}?trackId=${userInfo?.user?.id}`}
                text={eventCard.name}
              />
            </div>
          </div>
        </div>
        <div className={styles.asset__author}>
          <div className={styles.asset__author_items}>
            <div className="asset__author-item asset__author-first-item">
              <p className={styles.text__small_title}>{t("creator")}</p>
              <div style={{ display: "inline" }}>
                <div className={styles.creator__txt}>
                  {eventCard.creator.name}
                </div>
                <img
                  src="/img/icons/verified.svg"
                  alt="verified"
                  className={styles.verified__img}
                />
              </div>
            </div>
            <div className="asset__author-item">
              <p className={styles.text__small_title}>{t("collection")}</p>
              <div className={styles.collection__contain}>
                <LazyLoadImage
                  src={`${process.env.REACT_APP_ASSET_BASE_URL}/${eventCard.collection.picture_small}`}
                  alt="collecion image"
                  className={styles.asset__collection_img}
                />
                <Link
                  to={`/collection/${eventCard.collection.id}`}
                  className={styles.color__white}
                >
                  {eventCard.collection.name}
                </Link>
              </div>
            </div>
          </div>
          {eventCard.category === "Category1" && (
            <div className={styles.asset__author_items}>
              <div className="asset__author-item asset__author-first-item">
                <p className={styles.text__small_title}>{t("location")}</p>
                <div className={styles.flex__align_center}>
                  <p className={styles.text__small_val}>{eventCard.location}</p>
                </div>
              </div>
              <div className="asset__author-item asset__author-first-item">
                <p className={styles.text__small_title}>{t("date")}</p>
                <div className={styles.flex__align_center}>
                  <p className={styles.text__small_val}>
                    {moment(eventCard.date).format("ll")}
                  </p>
                </div>
              </div>
              <div className="asset__author-item asset__author-first-item">
                <p className={styles.text__small_title}>{t("time")}</p>
                <div className={styles.flex__align_center}>
                  <p className={styles.text__small_val}>
                    {fixedTime(eventCard.date)}
                  </p>
                </div>
              </div>
            </div>
          )}
          {eventCard.category === "Category3" &&
            (eventCard.period === null ? (
              <div className={styles.asset__author_items}>
                <div className="asset__author-item asset__author-first-item">
                  <p className={styles.text__small_title}>
                    {eventCard?.end_date ? t("start date") : t("date")}
                  </p>
                  <div className={styles.flex__align_center}>
                    {eventCard?.end_date ? (
                      <p className={styles.text__small_val}>
                        {moment(eventCard.date).format("lll")}
                      </p>
                    ) : (
                      <p className={styles.text__small_val}>
                        {moment(eventCard.date).format("ll")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="asset__author-item">
                  <p className={styles.text__small_title}>
                    {eventCard?.end_date ? t("end date") : t("time")}
                  </p>
                  <div className={styles.flex__align_center}>
                    {eventCard?.end_date ? (
                      <p className={styles.text__small_val}>
                        {moment(eventCard.end_date).format("lll")}
                      </p>
                    ) : (
                      <p className={styles.text__small_val}>
                        {fixedTime(eventCard.date)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.asset__author_items}>
                <div className="asset__author-item asset__author-first-item">
                  <p className={styles.text__small_title}>
                    {t("Benefit Period")}
                  </p>
                  <div className={styles.flex__align_center}>
                    <p className={styles.text__small_val}>
                      {eventCard.period} {eventCard.period_unit}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {JSON.parse(eventCard.addons).length > 0 &&
          eventCard.name !== '"Tech House Boat Party" Black Box Release' && (
            <div className={styles.asset__collection}>
              <div className={styles.asset__author_items}>
                <div className={styles.addons__detail_contain}>
                  <div className={styles.flex__align_center}>
                    <img
                      src="/img/benefitIcon.png"
                      alt="clock"
                      className={styles.map__img}
                    />
                    <p className="text__small-time">{t("benefits")}</p>
                  </div>
                  <div className={styles.addons__block_container}>
                    <div className={styles.addons__block}>
                      <div style={{ display: "flex" }}>
                        {JSON.parse(eventCard.addons).map(
                          (item, index) =>
                            index < 3 && (
                              <img
                                src="/img/benefit.png"
                                className={styles.addon_icon}
                                alt={item.name}
                                key={index}
                              />
                            )
                        )}
                      </div>
                      {JSON.parse(eventCard.addons).length > 3 && (
                        <p className={styles.see__more_txt}>
                          and {JSON.parse(eventCard.addons).length - 3} more
                        </p>
                      )}
                    </div>
                    <button
                      className={styles.addons__view_btn}
                      onClick={showAddons}
                    >
                      {t("view")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

        {eventCard.category !== "Category2" && (
          <div className={styles.asset__collection}>
            <div className={styles.asset__author_items}>
              {eventCard.picture_floormap !== null && (
                <div
                  className="asset__author-item asset__author-first-item"
                  style={{ marginBottom: 0 }}
                >
                  <div style={{ margin: "10px 0" }}>
                    <div className={styles.flex}>
                      <img
                        src="/img/icons/map.svg"
                        alt="map"
                        className={styles.map__img}
                      />
                      <p className={styles.text__small_title}>
                        {t("floor plan")}
                      </p>
                    </div>
                    <button className={styles.map__btn} onClick={showFloorMap}>
                      {t("view")}
                    </button>
                  </div>
                </div>
              )}
              <div
                className="asset__author-item"
                style={{ marginBottom: 0, flex: 1 }}
              >
                <div className={styles.asset__timing}>
                  <div className={styles.flex__align_center}>
                    <img
                      src="/img/icons/clock.svg"
                      alt="clock"
                      className={styles.map__img}
                    />
                    {eventCard.category === "Category1" ? (
                      <p className="text__small-time">{t("event starts in")}</p>
                    ) : (
                      <p className="text__small-time">
                        {t("end of the offer")}
                      </p>
                    )}
                  </div>
                  <div className={styles.mt__10}>
                    <EventCountDown event={eventCard} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(userInfo?.user?.user_type === "ADMIN" ||
          (userInfo?.user?.user_type === "BUSINESS" &&
            eventCard.creator.id === userInfo?.user?.id)) && (
          <div className={styles.asset__collection}>
            <div className={styles.asset__author_items}>
              <Link
                to={`/generate_link?origin=${window.location.href}`}
                className={styles.generate__links}
              >
                {t("generate referral links")}
              </Link>
              <Link
                to={`/promo_code_link?origin=${eventCard.id}`}
                className={styles.generate__links}
              >
                {t("generate promo code links")}
              </Link>
            </div>
          </div>
        )}
        {userInfo?.user?.user_type === "BUSINESS" &&
          eventCard.creator.id === userInfo?.user?.id && (
            <div className={styles.asset__collection}>
              <div className={styles.asset__author_items}>
                <button className={styles.btn__sale_stop} onClick={saleStop}>
                  {eventCard.sale_stop ? "Sale Start" : "Sale Stop"}
                </button>
              </div>
            </div>
          )}
        <div className={styles.w__100}>
          <div className={styles.purchase__contain}>
            {eventCard.category === "Category1" ? (
              <p className={styles.asset__desc}>
                {/* {eventCard.total_tickets - eventCard.buy_count}{" "}
                {t("ticket(s) left")} */}
              </p>
            ) : (
              <p className={styles.asset__desc}>
                {/* {eventCard.total_tickets - eventCard.buy_count}{" "}
                {t("item(s) left")} */}
              </p>
            )}
            <div style={{ minWidth: 80 }}>
              <p className="text__small-time text__small-tax">
                {eventCard.tax_include ? "INCL. Fees" : "EXCL. Fees"}
              </p>
              <p
                className={styles.asset__name}
                style={{ fontSize: 32, textAlign: "right" }}
              >
                <Fiat
                  price={getEventPrice(eventCard)}
                  currency={eventCard.currency}
                />
                &nbsp;
                <FiatSymbol />
              </p>
            </div>
          </div>
          {!captcha && (
            <div className={styles.color__white}>{t("capcha text")}</div>
          )}
          {!captcha && (
            <ReCAPTCHA
              className={styles.recaptcha}
              ref={recaptchaRef}
              sitekey="6LeaLwUgAAAAAIBN0ef2xzTx0rIfuLb1POyzr_ei"
              onChange={onChangeCaptcha}
            />
          )}
          <div className={styles.flex}>
            <div className={styles.assets__amount}>
              <p
                onClick={() => {
                  if (ticketAmount > 1) setTicketAmount(ticketAmount - 1);
                }}
              >
                -
              </p>
              <p className={styles.assets__amount_number}>{ticketAmount}</p>
              <p
                onClick={() => {
                  if (ticketAmount < 10) setTicketAmount(ticketAmount + 1);
                }}
              >
                +
              </p>
            </div>
            <button
              className={isAvailable() ? "buy__btn" : "buy__btn-disable"}
              onClick={isAvailable() ? handleBuyTicket : () => {}}
            >
              {buttonText()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCardInfo;
