// @ts-nocheck
import React, { useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";
import AppleSignin from "react-apple-signin-auth";
import FacebookAuth from "react-facebook-auth";

// import SignupSuccessModal from "../../../components/custom_modals/signup_success_modal";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { googleAuth } from "../../../api/auth";
import { appleAuth } from "../../../api/auth";
import { getTwoFactorStatus } from "../../../api/twofactor";
import { movePendingItems } from "../../../api/ticket";

const PageSignupWithSocial = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookies] = useCookies();
  const { userInfo, setUserInfo } = useUserContext();
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  // const redirectURL = searchParams.get("redirectURL");
  const { setLoading, setTwoStepStatus, addToast } = useAppContext();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const { t } = useTranslation();

  useEffect(() => {
    if (userInfo) navigate("/");
  }, [navigate, userInfo]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoading(true);
      try {
        const backendResponse = await googleAuth({
          token: tokenResponse.access_token,
        });
        if (backendResponse.success) {
          const twoFactorStatus = await getTwoFactorStatus({
            userId: backendResponse.data.user.id,
          });
          if (twoFactorStatus?.status) {
            setTwoStepStatus(twoFactorStatus?.status);
            setLoading(false);
          } else {
            setCookie("userInfo", backendResponse.data, { path: "/" });
            setUserInfo(backendResponse.data);
            const pendingTicket = await movePendingItems({
              email: backendResponse.data.user.email,
            });
            if (pendingTicket.success) {
              pendingTicket.status.map((item: string) => {
                addToast(item, { appearance: "info", autoDismiss: true });
                return true;
              });
            }
            setLoading(false);
            navigate("/");
          }
        }
      } catch (error) {
        console.error("Error during Google sign-up:", error);
      }
      setLoading(false);
    },
    onError: () => {
      console.error("Google Sign-Up Failed");
    },
  });

  const handleAppleSuccess = async (response: any) => {
    setLoading(true);
    try {
      const backendResponse = await appleAuth({
        token: response.authorization.id_token,
      });
      if (backendResponse.success) {
        const twoFactorStatus = await getTwoFactorStatus({
          userId: backendResponse.data.user.id,
        });
        if (twoFactorStatus?.status) {
          setTwoStepStatus(twoFactorStatus?.status);
          setLoading(false);
        } else {
          setCookie("userInfo", backendResponse.data, { path: "/" });
          setUserInfo(backendResponse.data);
          const pendingTicket = await movePendingItems({
            email: backendResponse.data.user.email,
          });
          if (pendingTicket.success) {
            pendingTicket.status.map((item: string) => {
              addToast(item, { appearance: "info", autoDismiss: true });
              return true;
            });
          }
          setLoading(false);
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Error during Google sign-up:", error);
    }
    setLoading(false);
  };

  const handleAppleError = (error: any) => {
    console.error("Apple Sign-In Error:", error);
  };

  const FacebookButton = ({ onClick }) => (
    <button onClick={onClick} className="signUp_social__btn">
      <img
        className="facebook__icon"
        src="img/media/icons-facebook.png"
        alt="facebook"
      />
      Continue with Facebook
    </button>
  );

  const authenticate = (response: any) => {
    console.log(response);
  };

  return (
    <div className="sign__container">
      <div className="sign">
        {isMobile && (
          <Link to="/" className="home__link">
            &times;
          </Link>
        )}
        <div
          className="sign__content"
          style={{ padding: isMobile ? 0 : "25px 0 0" }}
        >
          <div className="sign__form">
            <p
              className="sign__main-title"
              style={{ marginTop: isMobile ? "10px" : "50px" }}
            >
              {t("sign up")}
            </p>
            <Link to="/signup" className="signUp__btn" type="button">
              Continue with Email
            </Link>
            <button
              className="signUp_social__btn"
              onClick={() => handleGoogleLogin()}
            >
              <img
                className="media__icon"
                src="img/media/google.png"
                alt="google"
              />
              Continue with Google
            </button>
            <FacebookAuth
              appId="testID"
              callback={authenticate}
              component={FacebookButton}
            />
            <AppleSignin
              authOptions={{
                clientId: "backstageMarketplaceSign",
                redirectURI: "https://prelive.backstage.global",
                scope: "email name",
                state: "state", // Optional, can be any string
                nonce: "nonce", // Optional, can be any string
                usePopup: true, // Optional, defaults to false
              }}
              uiType="dark" // Can be "light" or "dark"
              render={(renderProps: any) => (
                <button
                  className="signUp_social__btn"
                  onClick={renderProps.onClick}
                >
                  <img
                    className="apple__icon"
                    src="img/media/apple.png"
                    alt="apple"
                  />
                  Continue with Apple
                </button>
              )}
              onSuccess={handleAppleSuccess}
              onError={handleAppleError}
            />
          </div>
        </div>
      </div>
      <div className="sign_bottom"></div>
    </div>
  );
};

export default PageSignupWithSocial;
